import React, {useState, useEffect} from 'react'
import './TeacherAvailability.css';
import {updateTeacherAvailability, getTeacherAvailability, getTeacherLessons} from '../../../../api';
import { AVAILABILITY_TEMPLATE } from './TeacherAvailabilityFormTemplate';
import { Button, Checkbox, CircularProgress } from '@material-ui/core'

import {addMinutes, parseISO} from 'date-fns'

import EditAvailability from './edit-availability/EditAvailability';



export const TeacherAvailability = ({teacherID}) => {
  const [ altViewActive, setAltViewActive ] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [availability, setAvailability] = useState(AVAILABILITY_TEMPLATE);
  const [lessons, setLessons] = useState([])


  useEffect(() => {
    if(teacherID) {
      let a = availability;
      setIsLoading(true)
      getTeacherAvailability(teacherID)
      .then(res => {
        setIsLoading(false)
        if(res.availability.length !== 0) {
          res.availability.forEach((row, i) => {
            row.forEach((item, j) => {
              a[i].times[j].available = item
            });
          });
          // console.log(a);
          // setAvailability(a);
        } else {
          res.availability.forEach((row, i) => {
            row.forEach((item, j) => {
              a[i].times[j].available = false
            });
          });
          console.log(a)
          // setAvailability(a);
        }
        getTeacherLessons(teacherID)
        .then(res => {
          console.log(res)
          if(res.lessons.length !== 0) {
            let lessonData = {
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
              sunday: []
            }
            res.lessons.forEach((item, i) => {
              let dt = new Date(item)
              if(dt.getDay() == 0) { lessonData.sunday.push(item) }
              if(dt.getDay() == 1) { lessonData.monday.push(item) }
              if(dt.getDay() == 2) { lessonData.tuesday.push(item) }
              if(dt.getDay() == 3) { lessonData.wednesday.push(item) }
              if(dt.getDay() == 4) { lessonData.thursday.push(item) }
              if(dt.getDay() == 5) { lessonData.friday.push(item) }
              if(dt.getDay() == 6) { lessonData.saturday.push(item) }
            });
            console.log(lessonData)
            a.forEach((item, i) => {
              item.times.forEach((jitem, j) => {
                a[i].times[j].lessons = []
              });
            });

            lessonData.sunday.forEach((t, i) => {
              let tArr = t.split('T');
              let tTime = tArr[1].split(':')
              let tHour = tTime[0];
              let tMinute = tTime[1]
              let pxAdjust = '0%'
              if(tMinute == '05') { pxAdjust = '8.3333%' }
              if(tMinute == '10') { pxAdjust = '16.6666%' }
              if(tMinute == '15') { pxAdjust = '25%' }
              if(tMinute == '20') { pxAdjust = '33.3333%' }
              if(tMinute == '25') { pxAdjust = '41.6666%' }
              if(tMinute == '30') { pxAdjust = '50%' }
              if(tMinute == '35') { pxAdjust = '58.3333%' }
              if(tMinute == '40') { pxAdjust = '66.6666%' }
              if(tMinute == '45') { pxAdjust = '75%' }
              if(tMinute == '50') { pxAdjust = '83.3333%' }
              if(tMinute == '55') { pxAdjust = '91.6666%' }

              let indexOfTimeID = a[6].times.map(e => e.timeID).indexOf(parseInt(tHour + 8));
              if(indexOfTimeID !== -1) {
                a[6].times[indexOfTimeID].lessons.push(
                  {
                    time: parseInt(tHour + tMinute),
                    pxAdjust: pxAdjust
                  }
                )
              }
            });

            lessonData.monday.forEach((t, i) => {
              let tArr = t.split('T');
              let tTime = tArr[1].split(':')
              let tHour = tTime[0];
              let tMinute = tTime[1]
              let pxAdjust = '0%'
              if(tMinute == '05') { pxAdjust = '8.3333%' }
              if(tMinute == '10') { pxAdjust = '16.6666%' }
              if(tMinute == '15') { pxAdjust = '25%' }
              if(tMinute == '20') { pxAdjust = '33.3333%' }
              if(tMinute == '25') { pxAdjust = '41.6666%' }
              if(tMinute == '30') { pxAdjust = '50%' }
              if(tMinute == '35') { pxAdjust = '58.3333%' }
              if(tMinute == '40') { pxAdjust = '66.6666%' }
              if(tMinute == '45') { pxAdjust = '75%' }
              if(tMinute == '50') { pxAdjust = '83.3333%' }
              if(tMinute == '55') { pxAdjust = '91.6666%' }
              let indexOfTimeID = availability[0].times.map(e => e.timeID).indexOf(parseInt(tHour + 8));
              if(indexOfTimeID !== -1) {
              availability[0].times[indexOfTimeID].lessons.push(
                {
                  time: parseInt(tHour + tMinute),
                  pxAdjust: pxAdjust
                }
              )
            }
            });

            lessonData.tuesday.forEach((t, i) => {
              let tArr = t.split('T');
              let tTime = tArr[1].split(':')
              let tHour = tTime[0];
              let tMinute = tTime[1]
              let pxAdjust = '0%'
              if(tMinute == '05') { pxAdjust = '8.3333%' }
              if(tMinute == '10') { pxAdjust = '16.6666%' }
              if(tMinute == '15') { pxAdjust = '25%' }
              if(tMinute == '20') { pxAdjust = '33.3333%' }
              if(tMinute == '25') { pxAdjust = '41.6666%' }
              if(tMinute == '30') { pxAdjust = '50%' }
              if(tMinute == '35') { pxAdjust = '58.3333%' }
              if(tMinute == '40') { pxAdjust = '66.6666%' }
              if(tMinute == '45') { pxAdjust = '75%' }
              if(tMinute == '50') { pxAdjust = '83.3333%' }
              if(tMinute == '55') { pxAdjust = '91.6666%' }
              let indexOfTimeID = a[1].times.map(e => e.timeID).indexOf(parseInt(tHour + 8));
              if(indexOfTimeID !== -1) {
              a[1].times[indexOfTimeID].lessons.push(
                {
                  time: parseInt(tHour + tMinute),
                  pxAdjust: pxAdjust
                }
              )
            }
            });

            lessonData.wednesday.forEach((t, i) => {
              let tArr = t.split('T');
              let tTime = tArr[1].split(':')
              let tHour = tTime[0];
              let tMinute = tTime[1]
              let pxAdjust = '0%'
              if(tMinute == '05') { pxAdjust = '8.3333%' }
              if(tMinute == '10') { pxAdjust = '16.6666%' }
              if(tMinute == '15') { pxAdjust = '25%' }
              if(tMinute == '20') { pxAdjust = '33.3333%' }
              if(tMinute == '25') { pxAdjust = '41.6666%' }
              if(tMinute == '30') { pxAdjust = '50%' }
              if(tMinute == '35') { pxAdjust = '58.3333%' }
              if(tMinute == '40') { pxAdjust = '66.6666%' }
              if(tMinute == '45') { pxAdjust = '75%' }
              if(tMinute == '50') { pxAdjust = '83.3333%' }
              if(tMinute == '55') { pxAdjust = '91.6666%' }
              let indexOfTimeID = a[2].times.map(e => e.timeID).indexOf(parseInt(tHour + 8));
              if(indexOfTimeID !== -1) {
              a[2].times[indexOfTimeID].lessons.push(
                {
                  time: parseInt(tHour + tMinute),
                  pxAdjust: pxAdjust
                }
              )
              }
            });

            lessonData.thursday.forEach((t, i) => {
              let tArr = t.split('T');
              let tTime = tArr[1].split(':')
              let tHour = tTime[0];
              let tMinute = tTime[1]
              let pxAdjust = '0%'
              if(tMinute == '05') { pxAdjust = '8.3333%' }
              if(tMinute == '10') { pxAdjust = '16.6666%' }
              if(tMinute == '15') { pxAdjust = '25%' }
              if(tMinute == '20') { pxAdjust = '33.3333%' }
              if(tMinute == '25') { pxAdjust = '41.6666%' }
              if(tMinute == '30') { pxAdjust = '50%' }
              if(tMinute == '35') { pxAdjust = '58.3333%' }
              if(tMinute == '40') { pxAdjust = '66.6666%' }
              if(tMinute == '45') { pxAdjust = '75%' }
              if(tMinute == '50') { pxAdjust = '83.3333%' }
              if(tMinute == '55') { pxAdjust = '91.6666%' }
              let indexOfTimeID = a[3].times.map(e => e.timeID).indexOf(parseInt(tHour + 8));
              if(indexOfTimeID !== -1) {
              a[3].times[indexOfTimeID].lessons.push(
                {
                  time: parseInt(tHour + tMinute),
                  pxAdjust: pxAdjust
                }
              )
            }
            });

            lessonData.friday.forEach((t, i) => {
              let tArr = t.split('T');
              let tTime = tArr[1].split(':')
              let tHour = tTime[0];
              let tMinute = tTime[1]
              let pxAdjust = '0%'
              if(tMinute == '05') { pxAdjust = '8.3333%' }
              if(tMinute == '10') { pxAdjust = '16.6666%' }
              if(tMinute == '15') { pxAdjust = '25%' }
              if(tMinute == '20') { pxAdjust = '33.3333%' }
              if(tMinute == '25') { pxAdjust = '41.6666%' }
              if(tMinute == '30') { pxAdjust = '50%' }
              if(tMinute == '35') { pxAdjust = '58.3333%' }
              if(tMinute == '40') { pxAdjust = '66.6666%' }
              if(tMinute == '45') { pxAdjust = '75%' }
              if(tMinute == '50') { pxAdjust = '83.3333%' }
              if(tMinute == '55') { pxAdjust = '91.6666%' }
              let indexOfTimeID = a[4].times.map(e => e.timeID).indexOf(parseInt(tHour + 8));
              if(indexOfTimeID !== -1) {
              a[4].times[indexOfTimeID].lessons.push(
                {
                  time: parseInt(tHour + tMinute),
                  pxAdjust: pxAdjust
                }
              )}
            });

            lessonData.saturday.forEach((t, i) => {
              let tArr = t.split('T');
              let tTime = tArr[1].split(':')
              let tHour = tTime[0];
              let tMinute = tTime[1]
              let pxAdjust = '0%'
              if(tMinute == '05') { pxAdjust = '8.3333%' }
              if(tMinute == '10') { pxAdjust = '16.6666%' }
              if(tMinute == '15') { pxAdjust = '25%' }
              if(tMinute == '20') { pxAdjust = '33.3333%' }
              if(tMinute == '25') { pxAdjust = '41.6666%' }
              if(tMinute == '30') { pxAdjust = '50%' }
              if(tMinute == '35') { pxAdjust = '58.3333%' }
              if(tMinute == '40') { pxAdjust = '66.6666%' }
              if(tMinute == '45') { pxAdjust = '75%' }
              if(tMinute == '50') { pxAdjust = '83.3333%' }
              if(tMinute == '55') { pxAdjust = '91.6666%' }
              let indexOfTimeID = a[5].times.map(e => e.timeID).indexOf(parseInt(tHour) + 8);
              if(indexOfTimeID !== -1) {
              a[5].times[indexOfTimeID].lessons.push(
                {
                  time: parseInt(tHour + tMinute),
                  pxAdjust: pxAdjust
                }
              )
            }
            });


            // lessons = [
            //     {
            //       time: 0530,
            //       pxAdjust: ''
            //     }
            // ]

            console.log(lessonData)
          }
          console.log(a)
          setAvailability(a)
        })
      }).catch(err => console.log(err))
    }
  }, [teacherID])

  const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  const TIMES = [
    {
      pl: '08:00',
      hk: '14:00'
    },
    {
      pl: '09:00',
      hk: '15:00'
    },
    {
      pl: '10:00',
      hk: '16:00'
    },
    {
      pl: '11:00',
      hk: '17:00'
    },
    {
      pl: '12:00',
      hk: '18:00'
    },
    {
      pl: '13:00',
      hk: '19:00'
    },
    {
      pl: '14:00',
      hk: '20:00'
    },
    {
      pl: '15:00',
      hk: '21:00'
    },
  ]

  const handleEditMode = (mode) => {
    if(mode == false) {
      let availabilityMatrix = []
      availability.forEach((day, i) => {
        let availabilityRow = [];
        day.times.forEach((time, i) => {
            availabilityRow.push(time.available)
        });
      availabilityMatrix.push(availabilityRow)
      });
      updateTeacherAvailability(teacherID, availabilityMatrix)
        .then(res => {
          if(res.success) {
            alert('success')
          } else {
            alert('failure')
          }
        })
    }
    setEditMode(mode)
  }

  const handleCheckbox = (time, day) => {
    let a = [...availability];
    a[day].times[time].available = !a[day].times[time].available
    setAvailability(() => a)
  }

  return(
    <>
      <div style={{padding: '15px'}}><Button variant={'outlined'} onClick={() => handleEditMode(!editMode)}>{!editMode ? 'Edit Availability' : 'Save'}</Button></div>
      { !isLoading && <table className={'sprout-availability-table'}>
        <thead>
          <tr>
          <th></th>
          { availability.map(a => <th>{a.day}</th>) }
          <th></th>
          </tr>
        </thead>
        <tbody>
          {
            availability[0].times.map((a, i) => {
              return (
                <>
                <tr key={i}>
                  { availability.map((cell, cIdx) => {
                    return (
                      <>
                      {cIdx == 0 && <td className='availability-table-time-left'><p>{TIMES[i].pl}</p></td>}
                        <td
                        className={`availability-cell ${availability[cIdx].times[i].available ? 'available' : 'unavailable'}`}
                        key={cIdx}
                        >
                        {editMode &&
                          <Checkbox
                            onChange={() => handleCheckbox(i, cIdx)}
                            checked={availability[cIdx].times[i].available} />}
                        { availability[cIdx].times[i].lessons.length !== 0 &&
                          availability[cIdx].times[i].lessons.map((l,lIdx) => {
                            return(
                              <>{ !editMode &&
                              <div className={'lesson-item'} style={{transform: `translateY(${availability[cIdx].times[i].lessons[lIdx].pxAdjust})`}}></div>
                              }</>
                            )
                          })
                        }
                        </td>
                      {cIdx == availability.length - 1 && <td className='availability-table-time-right'><p>{TIMES[i].hk}</p></td> }
                      </>
                    )}
                   )}
                </tr>
                </>
              )
            })
          }
        </tbody>
      </table>
    }
    {
      isLoading && <div style={{width: '100%', height: '420px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div>
    }
    </>
  )
}

export default TeacherAvailability
