export const TRIAL_FORM_TEMPLATE = [
  {
    id: 'trialName',
    label: 'Parent Name',
    type: 'text',
    column: 2
  },
  {
    id: 'trialStudent',
    label: 'Student Name',
    type: 'text',
    column: 2
  },
  {
    id: 'trialContactNo',
    label: 'Contact No (WhatsApp)',
    type: 'text',
    column: 2
  },
  {
    id: 'trialEmail',
    label: 'Contact Email (Google Account)',
    type: 'text',
    column: 2
  },
  {
    id: 'trialAge',
    label: 'Age Group',
    type: 'button-group',
    column: 1,
    options: [
      {
        value: 'K1',
        label: 'K1'
      },
      {
        value: 'K2',
        label: 'K2'
      },
      {
        value: 'K3',
        label: 'K3'
      },
      {
        value: 'P1',
        label: 'P1'
      },
      {
        value: 'P2',
        label: 'P2'
      },
      {
        value: 'P3',
        label: 'P3'
      },
      {
        value: 'P4',
        label: 'P4'
      },
      {
        value: 'P5',
        label: 'P5'
      },
      {
        value: 'P6',
        label: 'P6'
      },
    ]
  },
  {
    id: 'trialDescription',
    label: 'Notes',
    type: 'text',
    column: 1,
    multiple: true
  },
  {
    id: 'trialTeacher',
    label: 'Teacher',
    type: 'select',
    column: 2,
    options: [
      {
        value: null,
        label: 'Not Sure'
      }
    ]
  },
  {
    id: 'trialDate',
    label: 'Trial Date (HK)',
    type: 'date-time',
    column: 1
  }
]
