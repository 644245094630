import React from 'react';
import './Navigation.css';
import {Link, useHistory } from 'react-router-dom';

export const Navigation = ({active}) => {
  const history = useHistory();
  const logout = () => {
    localStorage.removeItem('key');
    localStorage.removeItem('timestamp');
    history.push('/login')
  }

  return (
    <>
      <nav>
          <ul>
            <li className={`${active == '/parents' ? 'active': ''}`}><Link to={'/parents'}>Parents</Link></li>
            <li className={`${active == '/teachers' ? 'active': ''}`}><Link to={'/teachers'}>Teachers</Link></li>
            <li className={`${active == '/invoices' ? 'active': ''}`}><Link to={'/invoices'}>Trials & Invoices</Link></li>
            <li className={`${active == '/lesson-plans' ? 'active': ''}`}><Link to={'/lesson-plans'}>Lesson Plans</Link></li>
            <li className={`${active == '/settings' ? 'active': ''}`}><Link to={'/settings'}>Settings</Link></li>
            <li onClick={logout}>Logout</li>
          </ul>
      </nav>
    </>
  )
}

export default Navigation;
