import React from "react";
import Card from '../../../components/core/card/Card';
import {Typography} from '@material-ui/core'
import {format} from 'date-fns'

export const LessonCalendarDay = ({lessons}) => {

    React.useEffect(() => {
        console.log(lessons)
    }, [lessons])
    return (
        <Card margin={'7px 0 7px 7px'}>
            <div className={'report-table-container'}>
                {
                lessons.map((lesson) => {
                    return (
                        <div className={'grid my-4 grid-cols-6 w-full'}>
                            <div className={'col-span-1 border-r border-r-gray-400'}>
                                <div className={'w-12 h-12 bg-red-400 rounded-[100%]'}>

                                </div>
                            </div>
                            <div className={'col-span-3'}>
                                <Typography>STUDENT NAME - PX</Typography>
                                <Typography className={'text-2xl'}>00:00</Typography>
                            </div>
                        </div>
                        )
                    })
                }

                {/*<table className={'report-table'}>
                    {lessons.map((lesson) => {
                    return (
                        <tr>
                        <td>{lesson.studentName}</td>
                        <td>{lesson.age}</td>
                        <td>{format(new Date(`${lesson.date} ${lesson.time}`), 'HH:mm aaa')}</td>
                        <td><button>Assessment</button></td>
                        </tr>
                    )
                    }) }
                </table>*/}
            </div>
        </Card>
    )
}

export default LessonCalendarDay;