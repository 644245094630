import React, {useState, useEffect} from 'react'
import './CheckboxTable.css';
import {Checkbox, CircularProgress} from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf'

export const CheckboxTable = ({options, handleCheckedValues}) => {
  // const [numPages, setNumPages] = useState(null);
  const [previewURI, setPreviewURI] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [checkValues, setCheckValues] = useState([])
  //

  const pdfOptions = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  useEffect(() => {
    // console.log(checkValues)
    let parentValues = []
    checkValues.forEach((value, i) => {
      parentValues.push({
        id: options[i].id,
        name: options[i].label,
        lessonMaterialID: options[i].lessonMaterialID,
        value: value
      })
    });
    handleCheckedValues(parentValues)
  }, [checkValues])

  useEffect(() => {
    if(options) {
      let arr = []
      options.forEach((opt, i) => {
        console.log(opt.defaultValue)
        arr.push(opt.defaultValue)
      });
      setCheckValues(arr)
    }
  }, [options])


  const handleCheckboxChange = (i) => {
    let val;
    if(checkValues[i] === 1) {
      val = -1
    } else if (checkValues[i] === -1) {
      val = 0
    } else if (checkValues[i] === 0) {
      val = 1
    }
    // handleCheckedValues([...checkValues.slice(0, i), val, checkValues.slice(i + 1)])
    setCheckValues((arr) => [...arr.slice(0, i), val, ...arr.slice(i + 1)])
  }

  const handleCheckboxTableRowMouseEnter = (e, uri) => {
    setPreviewURI(uri)
      setIsLoading(true)
  }

  const handleCheckboxTableRowMouseLeave = (e) => {
    setPreviewURI('')
    setIsLoading(false)
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
  // setNumPages(nextNumPages);
    setIsLoading(false)
  }
  return (
    <div className={'checkbox-table-container'}>
      <table className={'checkbox-table'}>
        { options.map((option, i) => {
          return (
            <tr className={`${checkValues[i] === 0 ? 'partly-complete' : ''} ${checkValues[i] === 1 ? 'complete' : ''}`} onMouseEnter={(e) => handleCheckboxTableRowMouseEnter(e, option.uri)} onMouseLeave={(e) => handleCheckboxTableRowMouseLeave(e)}>
            <td><Checkbox checked={checkValues[i] === 1} indeterminate={checkValues[i] === 0} onChange={() => handleCheckboxChange(i)}/></td>
            <td>{option.label}</td>
            </tr>
          )
        })
      }
      </table>
      <div className={'checkbox-table-pdf-preview'}>
      {previewURI &&
        <div className={'pdf-reader-container'}>
        <p className={'file-preview-uri-dev'}>{'http://localhost:3300/' + previewURI}</p>
        <Document file={`${encodeURI('http://localhost:3300/' + previewURI)}`}
           onLoadSuccess={onDocumentLoadSuccess} options={pdfOptions}
           >
                {!isLoading && <Page key={`page_1`} pageNumber={1} />}
                {isLoading && <CircularProgress />}
        </Document>

      </div>}
      </div>
    </div>
  )
}

export default CheckboxTable;
