import React, {useState, useEffect} from 'react';
import './AddLessonReport.css';
import { Typography, Button, Select, MenuItem, InputLabel, CircularProgress } from '@material-ui/core';
import Form from '../../../components/core/form/Form';
import {REPORT_FORM_TEMPLATE} from './ReportFormTemplate';
import { getLessonMaterialsByID, addLessonReportToLesson } from '../../../api'

export const AddLessonReport = ({lesson, onLessonReportComplete}) => {


  const initialiseForm = (template) => {
    let obj = {}
    template.forEach(group => {
      if(!group.hidden){
        obj[group.id] = null
      }
    })
    return obj;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [materialsLoading, setMaterialsLoading] = useState(false);
  const [lessonReportErr, setLessonReportErr] = useState('')
  const [lessonReportFormTemplate, setReportFormTemplate] = useState(REPORT_FORM_TEMPLATE)
  const [lessonReportForm, setLessonReportForm] = useState(initialiseForm(REPORT_FORM_TEMPLATE))

  useEffect(() => {
    if(lesson.lessonPlanID) {
      getLessonMaterials({
        invoiceID: lesson.invoiceID,
        teacherID: lesson.teacherID,
        customerID: lesson.customerID,
        lessonPlanIDs: lesson.lessonPlanID})
    }
  }, [lesson])

  const getLessonMaterials = (lessonPlanID) => {
    setMaterialsLoading(true)
    console.log(lessonPlanID)
    let lessonMaterialsList = []
    console.log(lesson)
    getLessonMaterialsByID(lessonPlanID).then(result => {
      console.log(result);
      setMaterialsLoading(false)
      result.materials.forEach((lessonMaterial, i) => {
        let lessonMaterialsTable = []
        lessonMaterial.forEach((lm, i) => {
          let completedMats = lesson.completedMaterials.flat(2)
          let completedIndex = completedMats.map(m => m.id).indexOf(lm._id);
          console.log(completedMats);
          console.log(completedIndex);
          // console.log( completedMats[completedIndex].value)
          lessonMaterialsTable.push({
            id: lm._id,
            lessonMaterialID: lm.lessonMaterialID,
            uri: lm.lessonMaterialURI,
            value: lm._id,
            defaultValue: completedMats[completedIndex] ? completedMats[completedIndex].value : -1,
            label: lm.lessonMaterialName
          });
        });
        lessonMaterialsList.push(lessonMaterialsTable)
      });
      let nxtLessons = []
      const lessonPlanIDMap = {
        'PR': 'Phonics/Reading',
        'SL': 'Speaking & Listening',
        'GW': 'Grammar & Writing'
      }
      result.nextLessons.forEach((nxtLesson, i) => {
        nxtLessons.push({
          value: nxtLesson,
          label: `
          ${nxtLesson[0]}${nxtLesson[1]}
          ${lessonPlanIDMap[`${nxtLesson[2]}${nxtLesson[3]}`]} |
          Module ${nxtLesson[5]}-${nxtLesson[7]}${nxtLesson[8] ? nxtLesson[8] : ''}`
        })
      });
      let templateCopy = []
      let formCopy = [];
      console.log(lessonMaterialsList)
      lessonReportFormTemplate.forEach((templateGroup) => {
        if(templateGroup.id == 'completedLessons') {
          lessonMaterialsList.forEach((lessonMaterialRow, i) => {
            templateCopy.push({
              id: `completedLessons_${i + 1}`,
              label: `Select completed lessons for ${lessonMaterialRow[0].lessonMaterialID}`,
              type: 'checkbox-table',
              column: lessonMaterialsList.length,
              options: lessonMaterialRow
            })
            let obj = {}
            obj[`completedLessons_${i + 1}`] = null
            formCopy.push(obj)
          });
        } else if (templateGroup.id == 'nextLessonModule') {
          templateCopy.push({
            id: 'nextLessonModule',
            label: 'Which lesson module will you cover next lesson?',
            type: 'select',
            column: 1,
            options: [
              {value: '0-0', label: 'Continue with current lesson materials'},
             ...nxtLessons,
           ]
          })
        } else {
          templateCopy.push(templateGroup)
        }
      });
      setReportFormTemplate(templateCopy)
      let form = lessonReportForm;
      delete form['completedLessons'];
      formCopy.forEach((fc, i) => {
        for(let key in fc) {
          form[key] = null
        }
      });
      setLessonReportForm(form)
    })
  }

  const setLessonReport = (e) => {
    let form = lessonReportForm;
    for(let key in form) {
      if(key === e.target.id) {
        form[key] = e.target.value
      }
    }
    setLessonReportForm(form)
  }

  const submitLessonReport = (e) => {
    e.preventDefault();
    console.log(lessonReportForm);
    for(let key in lessonReportForm) {
      if(!lessonReportForm[key]) {
        setLessonReportErr(key + 'is required!')
        return;
      }
    }
    setIsLoading(true)

    let completedLessonsArr = []
    for (let key in lessonReportForm) {
      if(key.includes('completedLessons')) {
        completedLessonsArr.push(lessonReportForm[key])
      }
    }
    let lessonReportData = {
      teacherComment: lessonReportForm.teacherComment,
      completedLessons: completedLessonsArr,
      nextLessonModule: lessonReportForm.nextLessonModule
    }
    addLessonReportToLesson(lesson.id, lessonReportData)
      .then(res => {
        if(res.success) {
          setIsLoading(false)
          // alert('success');
          onLessonReportComplete()
          console.log(res);
        } else {
          setIsLoading(false)
          setLessonReportErr('Something went wrong when adding the lesson report')
        }
      })
  }

  return (
    <div>
    {!materialsLoading &&
    <Form
      submitTxt={`Submit Lesson Report`}
      formGroups={lessonReportFormTemplate}
      handleSubmit={submitLessonReport}
      setFormValues={setLessonReport}
      isLoading={isLoading}
      formErr={lessonReportErr}
    />
    }
    {materialsLoading &&
      <div style={{width: '100%', minHeight: '420px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress />
      </div>
      }
    </div>
  )
}

export default AddLessonReport
