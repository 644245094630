import React, { useContext, useState, useEffect } from 'react';
import './InvoiceDashboard.css';
import CustomerView from '../customers/customer-view/CustomerView';
import AssignTeacher from './assign-teacher/AssignTeacher';
import LessonDashboard from '../lessons/LessonDashboard';
import { Link, useHistory } from 'react-router-dom';
import Page from '../../../components/core/page/Page';
import Wrapper from '../../../components/core/wrapper/Wrapper';
import Table from '../../../components/core/table/Table';
import Card from '../../../components/core/card/Card';
import Column from '../../../components/core/column/Column';
import Modal from '../../../components/core/modal/Modal';
import modalContext from "../../../context/ModalContext";
import { getAllInvoices, getAllTrials } from "../../../api"
import { camelToString } from '../../../helpers';
import { Button, Box, Typography, CircularProgress } from '@material-ui/core';
import { parseISO, addMinutes, format } from 'date-fns';
import checkAuth from '../../auth/auth-helpers.js';

export const InvoiceDashboard = () => {
  const history = useHistory();
  const { modalOpen, setModalOpen } = useContext(modalContext)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ invoiceUnpaidTableHeader, setInvoiceUnpaidTableHeader ] = useState(null)
  const [ invoicePaidTableHeader, setInvoicePaidTableHeader] = useState(null)
  const [ trialTableHeader, setTrialTableHeader ] = useState(null)
  const [ invoiceTableData, setInvoiceTableData ] = useState(null)
  const [ paidInvoiceTableData, setPaidInvoiceTableData ] = useState([])
  const [ unpaidInvoiceTableData, setUnpaidInvoiceTableData ] = useState([])
  const [ trialTableData, setTrialTableData ] = useState([])
  const [ isTrial, setIsTrial] = useState(false)

  const [ modalID, setModalID ] = useState('');
  const [ modalSelector, setModalSelector ] = useState(null)

  useEffect(() => {
    checkAuth(history)
    getInvoices()
    getTrials()
  }, [history])

  const getInvoices = () => {
    setIsLoading(true)
    getAllInvoices().then(result => {
      console.log(result)
      let headerData = []
      for(let key in result.invoices[0]) {
        if(key !== '_id' && key !== 'customerID' && key !== 'currency' && key !== 'paymentReceived') {
          if(key !== 'teacherID') {
            headerData.push({data: camelToString(key), value: key})
          } else {
            headerData.push({data: 'Teacher', value: key})
          }
        }
      }
      console.log(headerData)
      setInvoiceUnpaidTableHeader(headerData)
      let paidHeaderData = [...headerData]
      let paymentIdx = paidHeaderData.map(e => e.value).indexOf('paymentURL');
      paidHeaderData[paymentIdx] = {data: `Lessons`, value: 'viewLessons'}
      setInvoiceUnpaidTableHeader(headerData)
      setInvoicePaidTableHeader(paidHeaderData)
      let unpaidTableData = []
      let paidTableData = []
      result.invoices.forEach((invoice, i) => {
        let rowData = []
        let isPaid = false;
        for(let key in invoice) {
          if(key !== '_id' && key !== 'customerID' && key !== 'currency' && key !== 'paymentReceived') {
            if (key == 'teacherID') {
              rowData.push({data: invoice[key], value: key, button: true, buttonLabel: invoice[key] ? result.teachers[`${invoice[key]}`] : 'Assign'})
            } else if (key == 'price'){
              rowData.push({data: `${invoice[key]} ${invoice['currency']}`, value: key})
            } else if (key == 'paymentURL') {
              rowData.push({data: invoice[key], value: key, button: true, buttonLabel: 'Copy URL'})
            } else {
              rowData.push({data: invoice[key], value: key})
            }
          } else if (key == 'paymentReceived') {
            isPaid = invoice[key];
          } else {
            rowData.push({data: invoice[key], value: key, hide: true})
          }
        }
        if(isPaid) {
          let paymentIdx = rowData.map(e => e.value).indexOf('paymentURL');
          rowData[paymentIdx] = {data: `viewLessons`, value: 'viewLessons', button: true, buttonLabel: 'View Lessons'}
          paidTableData.push({rows: rowData});
        } else {
          unpaidTableData.push({rows: rowData});
        }
      })
      setPaidInvoiceTableData(paidTableData);
      setUnpaidInvoiceTableData(unpaidTableData);
      setIsLoading(false)
    }).catch(err => {
      setIsLoading(false)
      alert("There was an error when fetching the invoices")
      console.log(err)
    })
  }

  const getTrials = () => {
    getAllTrials().then(result => {
      console.log(result)
      let headerData = []
      for(let key in result.trials[0]) {
        if(key !== '_id' && key !== 'description' && key !== 'phone' && key !== 'email' && key !== '__v' && key !== 'createdAt' && key !== 'calendarID') {
            headerData.push({data: camelToString(key), value: key})
        }
      }
      setTrialTableHeader(headerData)
      let trialTableData = []

      const convertToHKDateString = (d) => {
          let timezoneOffset = parseISO(d).getTimezoneOffset();
          let arr = JSON.stringify(addMinutes(parseISO(d),timezoneOffset +  8 * 60)).split('.')
          let date = `${arr[0]}+00:00`.substring(1)
          return `${format(new Date(date), 'HH:mm EE do MMM')} HKT`
      }

      result.trials.forEach((trial, i) => {
        let rowData = []
        let isPaid = false;
        for(let key in trial) {
          if(key !== '_id'  && key !== 'description' && key !== 'phone' && key !== 'email' && key !== '__v' && key !== 'createdAt' && key !== 'calendarID') {
            if(key == 'calendar') {
              rowData.push({data: trial[key], value: key, button: true, buttonLabel: 'View Calendar'})
            } else if (key == 'teacherID') {
              rowData.push({data: trial[key], value: key, button: true, buttonLabel: trial[key] ? result.teachers[`${trial[key]}`] : 'Assign'})
            } else if (key == 'date') {
              rowData.push({data: convertToHKDateString(trial[key]), value: key})
            } else {
              rowData.push({data: trial[key], value: key})
            }
          } else {
            rowData.push({data: trial[key], value: key, hide: true})
          }
        }
        trialTableData.push({rows: rowData});
      })
      setTrialTableData(trialTableData);
    }).catch(err => {
      console.log(err)
    })
  }

  const handleTrialActionRowClick = (target, row, cell) => {
    const selectedID = trialTableData[row].rows[cell].value
    if(selectedID == 'teacherID') {
      setIsTrial(true)
      setModalOpen(true)
      setModalID(trialTableData[row].rows[0].data)
      setModalSelector('assign')
    }
    if (selectedID == 'calendar') {
      window.open(trialTableData[row].rows[cell].data)
    }
  }

  const handlePaidActionRowClick = (target, row, cell) => {
    const selectedID = paidInvoiceTableData[row].rows[cell].value
    if(selectedID == 'payee') {
      setIsTrial(false)
      setModalOpen(true)
      setModalID(paidInvoiceTableData[row].rows[1].data)
      setModalSelector('customer')
    }
    if(selectedID == 'teacherID') {
      setIsTrial(false)
      setModalOpen(true)
      setModalID(paidInvoiceTableData[row].rows[0].data)
      setModalSelector('assign')
    }
    if(selectedID == 'viewLessons') {
      setIsTrial(false)
      setModalID(paidInvoiceTableData[row].rows[0].data)
      setModalSelector('lessons')
      setModalOpen(true)
      // alert(paidInvoiceTableData[row].rows[0].data)
    }
  }

  const handleUnpaidActionRowClick = (target, row, cell) => {
    const selectedID = unpaidInvoiceTableData[row].rows[cell].value
    if(selectedID == 'payee' || selectedID == 'teacherID') {
      setIsTrial(false)
      setModalOpen(true)
      setModalID(unpaidInvoiceTableData[row].rows[selectedID == 'payee' ? 1 : 0].data)
      setModalSelector(selectedID == 'payee' ? 'customer' : 'assign')
    }
    if (selectedID == 'paymentURL') {
      navigator.clipboard.writeText(unpaidInvoiceTableData[row].rows[cell].data)
    }
  }

  const handleAssignClose = () => {
    setModalOpen(false)
    if(isTrial) {
      getTrials()
    } else {
      getInvoices()
    }
  }

  const handleLessonClose = () => {
    setModalOpen(false)
    setModalSelector(null)
    setModalID(null)
  }

  return (
    <>
    <div className='invoice-dashboard'>
      <Page>
        <Wrapper>
          <Card>
            <div className='table-controls-container'>
              <Column cx={3}>
                <Box style={{padding: '0 14px 7px 0',boxSizing:'border-box'}}>
                </Box>
              </Column>
              <Column cx={3}>
                <Box style={{padding: '0 7px 7px 7px',boxSizing:'border-box'}}>
                <Link className='button-link' to={'/create-trial'}><Button variant="outlined" align="center" className='btn-theme' type='submit'>Create Trial</Button></Link>
                </Box>
              </Column>
              <Column cx={3}>
                <Box style={{padding: '0 0 7px 14px',boxSizing:'border-box'}}>
                <Link className='button-link' to={'/generate-invoice'}><Button variant="outlined" align="center" className='btn-theme' type='submit'>Generate Invoice</Button></Link>
                </Box>
              </Column>
            </div>

            { trialTableData.length !== 0 &&
              <>
                <div className='trial-table'>
                  <Typography variant={'h6'}>Trial Lessons</Typography>
                  <Table
                    onActionRowClick={handleTrialActionRowClick}
                    headers={trialTableHeader}
                    data={trialTableData} />
                    <br />
                </div>
              </>
            }
            { unpaidInvoiceTableData.length !== 0 &&
              <>
                <div className='unpaid-table'>
                  <Typography variant={'h6'}>Unpaid Invoices</Typography>
                  <Table
                    onActionRowClick={handleUnpaidActionRowClick}
                    headers={invoiceUnpaidTableHeader}
                    data={unpaidInvoiceTableData} />
                    <br />
                </div>
              </>
            }
            { paidInvoiceTableData.length !== 0 &&
              <>
                <div className='paid-table'>
                  <Typography variant={'h6'}>Paid Invoices</Typography>
                  <Table
                    onActionRowClick={handlePaidActionRowClick}
                    headers={invoicePaidTableHeader}
                    data={paidInvoiceTableData} />
                </div>
              </>
            }
            {
              paidInvoiceTableData.length == 0 && unpaidInvoiceTableData.length == 0 && trialTableData.length == 0 && !isLoading &&
              <Typography variant={'subtitle2'} align={'center'}>There is no table data</Typography>
            }
            {
              isLoading && <div style={{width: '100%', height: '420px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div>
            }
          </Card>
        </Wrapper>
      </Page>
    </div>
    <Modal component={
        <>
          { modalSelector == 'customer' && <CustomerView customerID={modalID}/> }
          { modalSelector == 'assign' && <AssignTeacher invoiceID={modalID} isTrial={isTrial} handleClose={handleAssignClose}/> }
          { modalSelector == 'lessons' && <LessonDashboard invoiceID={modalID} handleClose={handleLessonClose} altView/>}
        </>
      }/>
  </>
  )
}
export default InvoiceDashboard;
