import logo from './logo.svg';
import './App.css';
import React from 'react';


import Login from './pages/auth/login/Login';
import Dashboard from './pages/dashboard/Dashboard'
import TeacherDashboard from './pages/dashboard/teachers/TeacherDashboard'

import TeacherPortal from './pages/teacher-portal/TeacherPortal'
import StudentPortal from './pages/student-portal/StudentPortal'
import CustomerDashboard from './pages/dashboard/customers/CustomerDashboard'
import InvoiceDashboard from './pages/dashboard/payments/InvoiceDashboard'
import LessonDashboard from './pages/dashboard/lessons/LessonDashboard'
import AdminDashboard from './pages/dashboard/admin/AdminDashboard'
import LessonPlanDashboard from './pages/dashboard/lessonplans/LessonPlanDashboard'
import LabsDashboard from './pages/dashboard/labs/LabsDashboard'
import GPT3Generator from './pages/dashboard/labs/gpt3/gpt3-generator'
import CreateTrial from './pages/dashboard/lessons/create-trial/CreateTrial'
import GenerateInvoice from './pages/dashboard/payments/generate-invoice/GenerateInvoice'
import GapFill from './pages/dashboard/labs/exercise-generator/gap-fill/GapFill'
import modalContext from "./context/ModalContext";

import Navigation from './components/navigation/Navigation';

import InvoiceProvider from './providers/InvoiceProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


function App() {
  const [modalOpen, setModalOpen] = React.useState(false);
  return (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
  <modalContext.Provider value={{ modalOpen, setModalOpen }}>
    <Router>
      <Switch>
        <Route exact path={['/','/login']}>
          <Login />
        </Route>
        <Route path={'/dashboard'}>
          <Dashboard />
        </Route>
        <Route path={'/teachers'}>
        <Navigation active={'/teachers'} />
          <TeacherDashboard />
        </Route>
        <Route path={'/teacher/dashboard'}>
        <Navigation active={'/teachers'} />
          <TeacherPortal />
        </Route>
        <Route path={'/invoices'}>
          <Navigation active={'/invoices'} />
          <InvoiceDashboard />
        </Route>
        <Route path={'/parents'}>
          <Navigation active={'/parents'} />
          <CustomerDashboard />
        </Route>
        <Route path={'/parent/dashboard'}>
        <Navigation active={'/parents'} />
          <StudentPortal />
        </Route>
        <Route path={'/lessons'}>
          <Navigation active={'/lessons'} />
          <LessonDashboard />
        </Route>
        <Route path={'/lesson-plans'}>
          <Navigation active={'/lesson-plans'} />
          <LessonPlanDashboard />
        </Route>
        <Route path={'/labs'}>
          <Navigation active={'/labs'} />
          <LabsDashboard />
        </Route>
        <Route path={'/gap-fill'}>
          <Navigation active={'/gap-fill'} />
          <GapFill />
        </Route>
        <Route path={'/sentence-generator'}>
          <Navigation active={'/labs'} />
          <GPT3Generator />
        </Route>
        <Route path={'/generate-invoice'}>
          <Navigation active={'/generate-invoice'} />
          <GenerateInvoice />
        </Route>
        <Route path={'/create-trial'}>
          <Navigation active={'/create-trial'} />
          <CreateTrial />
        </Route>
        <Route path={'/settings'}>
          <Navigation active={'/settings'} />
          <AdminDashboard />
        </Route>
      </Switch>
    </Router>
  </modalContext.Provider>
  </MuiPickersUtilsProvider>
  );
}

export default App;
