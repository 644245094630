import axios from 'axios';
const BASE_URL= process.env.REACT_APP_API_BASE_URL;
//    ╔═══════════════╗
//           BASE
//    ╚═══════════════╝
const invokeWebService = async (url, method, data) => {
  try {
    const token = localStorage.getItem('key')
    const response = await axios({
      method,
      url: BASE_URL + url,
      data: data || {},
      headers: {
        "Authorization": 'Bearer ' + token,
        "Content-Type": 'application/json',
      },
      timeout: 90000,
    });
    console.log(response);
    return response.data;
  } catch(error) {
    console.log(error.message)
    if (error?.response?.data?.error?.message) {
      throw new Error(error.response.data.error.message);
    }
  }
}
//    ╔═══════════════╗
//          USERS
//    ╚═══════════════╝

export const loginUser = async(credentials) => {
  return invokeWebService(`auth/login`, 'POST', credentials)
}

//    ╔═══════════════╗
//       DRIVE SYNC
//    ╚═══════════════╝

export const syncToGoogleDrive = async() => {
  return invokeWebService(`admin/update-drive`, 'GET')
}

export const updateLessonsWithCustomerID = async() => {
  return invokeWebService(`admin/update-lesson-customer-id`, 'GET')
}



//    ╔═══════════════╗
//         INVOICES
//    ╚═══════════════╝

export const generateInvoice = async(invoice) => {
  return invokeWebService(`invoice/generate`, 'POST', invoice);
}

export const getInvoice = async(id) => {
  return invokeWebService(`invoice/${id}`, 'GET')
}

export const getAllInvoices = async() => {
  return invokeWebService(`invoice/all`, 'GET')
}

export const assignTeacherToLessons = async(id, teacher, isTrial) => {
  if(!isTrial) {
    return invokeWebService(`invoice/assign/${id}`, 'POST', teacher);
  } else {
    return invokeWebService(`lesson/assign-trial/${id}`, 'POST', teacher);
  }
}

//    ╔═══════════════╗
//        CUSTOMERS
//    ╚═══════════════╝

export const getCustomerByID = async(id) => {
  return invokeWebService(`customer/${id}`, 'GET')
}

export const getAllCustomers = async() => {
  return invokeWebService(`customer`, 'GET')
}

export const getNextCustomerLessons = async(id) => {
  return invokeWebService(`customer/${id}/lessons/next`, 'GET')
}

export const getUpcomingCustomerInvoiceLessons = async(id) => {
  return invokeWebService(`customer/${id}/lessons/upcoming`, 'GET')
}

//    ╔═══════════════╗
//         TEACHERS
//    ╚═══════════════╝

export const createTeacher = async(teacher) => {
  return invokeWebService(`teacher/create`, 'POST', teacher)
}

export const getAllTeachers = async() => {
  return invokeWebService(`teacher/all`, 'GET')
}

export const getTeacherById = async(id) => {
  return invokeWebService(`teacher/${id}`, 'GET')  
}

export const updateTeacherAvailability = async(id, availability) => {
  return invokeWebService(`teacher/update-availability/${id}`, 'POST', availability)
}

export const getTeacherAvailability = async(id) => {
  return invokeWebService(`teacher/get-availability/${id}`, 'GET')
}

export const getTeacherLessons = async(id) => {
  return invokeWebService(`teacher/get-lessons/${id}`, 'GET')
}

export const getLessonReportsByTeacherID = async(id) => {
  return invokeWebService(`teacher/get-lesson-reports/${id}`, 'GET')
}


//    ╔═══════════════╗
//         LESSONS
//    ╚═══════════════╝

export const getLessonsByInvoiceID = async(id) => {
  return invokeWebService(`lesson/invoice/${id}`, 'GET')
}

export const assignTeacherToSingleLesson = async(id, teacher) => {
  return invokeWebService(`lesson/assign/${id}`, 'POST', teacher)
}

export const rescheduleSingleLesson = async(id, datetime) => {
  return invokeWebService(`lesson/reschedule/${id}`, 'POST', datetime)
}

export const addLessonReportToLesson = async(id, report) => {
  return invokeWebService(`lesson/report/${id}`, 'POST', report)
}

//    ╔═══════════════╗
//         TRIALS
//    ╚═══════════════╝

export const createTrial = async(trial) => {
  return invokeWebService(`lesson/create-trial`, 'POST', trial)
}

export const getAllTrials = async() => {
  return invokeWebService(`lesson/retrieve-trials`, 'GET')
}

//    ╔═══════════════╗
//       LESSON PLANS
//    ╚═══════════════╝

export const getSelectedLessonPlans = async(query) => {
  return invokeWebService(`lesson-plan/query`, 'POST', query)
}

export const getLessonMaterialsByID = async(id) => {
  return invokeWebService(`lesson-plan/get-by-id`, 'POST', id)
}

export const getLessonWordBank = async(id) => {
  return invokeWebService(`lesson-plan/get-word-bank`, 'POST', id)
}

export const addWordToLesson = async(payload) => {
  return invokeWebService(`lesson-plan/add-word`, 'POST', payload)
}

export const regenerateGPT3Sentence = async(payload) => {
  return invokeWebService(`lesson-plan/regenerate-sentence`, 'POST', payload)
}

export const getWordBankSentences = async(payload) => {
  return invokeWebService(`lesson-plan/get-word-bank-sentences`, 'POST', payload)
}

export const getWordBankImages = async(payload) => {
  return invokeWebService(`lesson-plan/get-word-bank-images`, 'POST', payload)
}

export const uploadWordBankImage = async(payload) => {
  return invokeWebService(`lesson-plan/upload-word-bank-image`, 'POST', payload)
}

export const getExerciseSentences = async(payload) => {
  return invokeWebService(`exercises/get-sentences`, 'POST', payload)
}



//    ╔═══════════════╗
//          LABS
//    ╚═══════════════╝

export const generateGPT3Sentence = async(prompt) => {
  console.log(prompt)
  return invokeWebService(`labs/gpt-3/generate`, 'POST', prompt)
}

export const generateGPT3ExampleSentences = async(prompt) => {
  return invokeWebService(`lesson-plan/generate-sentences`, 'POST', prompt)
}
