import React from "react";
import Card from '../../../components/core/card/Card';
import {Typography} from '@material-ui/core'
import {format} from 'date-fns'

export const LessonTrials = ({trials}) => {
    return (
        <Card margin={'7px 0 7px 7px'}>
            <Typography>You have {trials.length} upcoming trial lessons</Typography>
            <div className={'report-table-container'}>
                <table className={'report-table'}>
                    {trials.map((trial) => {
                    return (
                        <tr>
                        <td>{trial.studentName}</td>
                        <td>{trial.age}</td>
                        <td>{format(new Date(trial.date), 'HH:mm / eee dd MMM')}</td>
                        <td><button>Assessment</button></td>
                        </tr>
                    )
                    })}
                </table>
            </div>
        </Card>
    )
}

export default LessonTrials;