export const INVOICE_FORM_TEMPLATE = [
  {
    id: 'isNewCustomer',
    label: 'Is this a new customer?',
    type: 'button-group',
    default: 'no',
    column: 1,
    options: [
      {
        value: 'yes',
        label: 'Yes'
      },
      {
        value: 'no',
        label: 'No'
      },
    ]
  },
  {
    id: 'invoiceRecipient',
    label: 'Invoice Recipient',
    type: 'text',
    column: 3,
    toggleID: 1,
    hidden: false
  },
  {
  id: 'invoiceRecipientSelect',
  label: 'Invoice Recipient',
  type: 'select',
  column: 1,
  toggleID: 1,
  hidden: true
  },
  {
    id: 'invoiceStudent',
    label: 'Student Name',
    type: 'text',
    column: 3,
    toggleID: 1,
    hidden: false
  },
  {
    id: 'contactNumber',
    label: 'Contact Number (WhatsApp)',
    type: 'text',
    column: 3,
    toggleID: 1,
    hidden: false
  },
  // {
  //   id: 'contactEmail',
  //   label: 'Contact Email (Google Account)',
  //   type: 'text',
  //   column: 2,
  //   toggleID: 1,
  //   hidden: false
  // },
  {
    id: 'ageGroup',
    label: 'Age Group',
    type: 'button-group',
    column: 1,
    toggleID: 1,
    hidden: false,
    options: [
      {
        value: 'K1',
        label: 'K1'
      },
      {
        value: 'K2',
        label: 'K2'
      },
      {
        value: 'K3',
        label: 'K3'
      },
      {
        value: 'P1',
        label: 'P1'
      },
      {
        value: 'P2',
        label: 'P2'
      },
      {
        value: 'P3',
        label: 'P3'
      },
      {
        value: 'P4',
        label: 'P4'
      },
      {
        value: 'P5',
        label: 'P5'
      },
      {
        value: 'P6',
        label: 'P6'
      },
    ]
  },
  {
    id: 'lessonType',
    label: 'Lesson Type',
    type: 'button-group',
    column: 2,
    multiple: true,
    options: [
      {
        value: 'Phonics',
        label: 'Phonics'
      },
      {
        value: 'Speaking',
        label: 'Speaking'
      },
      {
        value: 'Writing',
        label: 'Writing'
      },
    ]
  },
  {
    id: 'lessonFrequency',
    label: 'Number of Weekly Lessons',
    type: 'button-group',
    column: 2,
    default: '1',
    options: [
      {
        value: '1',
        label: '1'
      },
      {
        value: '2',
        label: '2'
      },
      {
        value: '3',
        label: '3'
      },
      {
        value: '4',
        label: '4'
      }
    ]
  },
  {
    id: 'startingDateTime_1',
    label: 'First Starting Date (HK)',
    type: 'date-time',
    column: 1,
    hidden: true,
  },
  {
    id: 'startingDateTime_2',
    label: 'Second Starting Date (HK)',
    type: 'date-time',
    column: 1,
    hidden: true
  },
  {
    id: 'startingDateTime_3',
    label: 'Third Starting Date (HK)',
    type: 'date-time',
    column: 1,
    hidden: true
  },
  {
    id: 'startingDateTime_4',
    label: 'Fourth Starting Date (HK)',
    type: 'date-time',
    column: 1,
    hidden: true
  },
  {
    id: 'lessonQuantity',
    label: 'Number of Lessons in Invoice',
    type: 'number',
    column: 4,
    controls: true
  },
  {
    id: 'lastLessonInInvoice',
    label: 'Date of last lesson in invoice',
    type: 'dynamic-text',
    column: 4,
    value: '------, --/--/----'
  },
  {
    id: 'hasCustomInvoice',
    label: 'Set custom price?',
    type: 'button-group',
    column: 4,
    default: 'no',
    isToggle: true,
    options: [
      {
        value: 'yes',
        label: 'Yes'
      },
      {
        value: 'no',
        label: 'No'
      },
    ]
  },
  {
    id: 'customInvoiceAmount',
    label: 'Custom Price per Lesson',
    type: 'number',
    column: 4,
    toggleID: 0,
    hidden: true
  },
  {
    id: 'teacherSelect',
    label: 'Teacher',
    type: 'select',
    column: 1,
    },
  // {
  //   id: 'firstModuleOfInvoice',
  //   label: 'Select the module for this invoice',
  //   type: 'select',
  //   column: 2,
  //   toggleID: 1,
  //   hidden: false,
  //   options: [
  //     {
  //       value: 1,
  //       label: 'Module 1'
  //     },
  //     {
  //       value: 2,
  //       label: 'Module 2'
  //     },
  //     {
  //       value: 3,
  //       label: 'Module 3'
  //     },
  //     {
  //       value: 4,
  //       label: 'Module 4'
  //     },
  //     {
  //       value: 5,
  //       label: 'Module 5'
  //     },
  //     {
  //       value: 6,
  //       label: 'Module 6'
  //     },
  //   ]
  // },
  // {
  //   id: 'firstLessonOfInvoice',
  //   label: 'Which lesson plan in this module?',
  //   type: 'select',
  //   column: 2,
  //   toggleID: 1,
  //   hidden: false,
  //   options: [
  //     {
  //       value: 1,
  //       label: 'Lesson 1'
  //     },
  //     {
  //       value: 2,
  //       label: 'Lesson 2'
  //     },
  //     {
  //       value: 3,
  //       label: 'Lesson 3'
  //     },
  //     {
  //       value: 4,
  //       label: 'Lesson 4'
  //     },
  //   ]
  // },
  {
    id: 'numberOfMakeupLessons',
    label: 'Are there any make up lessons?',
    type: 'button-group',
    column: 1,
    default: '0',
    options: [
      {
        value: '0',
        label: '0'
      },
      {
        value: '1',
        label: '1'
      },
      {
        value: '2',
        label: '2'
      },
      {
        value: '3',
        label: '3'
      },
      {
        value: '4',
        label: '4'
      }
    ]
  },
  {
    id: 'makeupLessonDateTime_1',
    label: 'First Make Up Date (HK)',
    type: 'date-time',
    column: 1,
    hidden: true,
  },
  {
    id: 'makeupLessonDateTime_2',
    label: 'Second Make Up Date (HK)',
    type: 'date-time',
    column: 1,
    hidden: true
  },
  {
    id: 'makeupLessonDateTime_3',
    label: 'Third Make Up Date (HK)',
    type: 'date-time',
    column: 1,
    hidden: true
  },
  {
    id: 'makeupLessonDateTime_4',
    label: 'Fourth Make Up Date (HK)',
    type: 'date-time',
    column: 1,
    hidden: true
  },
  {
    id: 'rescheduleLesson',
    label: 'Do you need to reschedule / cancel any lessons for holidays?',
    buttonText: 'Reschedule / Cancel Lessons',
    type: 'action-button',
    column:  1,
    default: 'no',
  },
  // {
  //   id: 'rescheduleLesson',
  //   label: 'Reschedule Lesson',
  //   type: 'action-button',
  //   column: 2,
  //   hidden: false
  // }
]


//
// options: customers // * Note that this will need to be added in component
