import React from 'react';
import './Table.css';
import {Button} from '@material-ui/core'

export const Table = (props) => {
  const handleRowClick = (e, i) => {
    props.onRowClick(props.data[i].rows[props.returnRowValueIndex].data)
  }

  const handleActionRowClick = (e, i, j) => {
    props.onActionRowClick(e, i, j)
  }

  return(
    <>
    <table className='sprout-admin-table'>
      <thead>
        <tr>
        {props.headers && props.headers.map((hdr, i) => {
          return(
            <>
            {!hdr.hide && <th key={i}>{hdr.data}</th>}
            </>
          )
        })}
        </tr>
      </thead>
      <tbody>
          {props.data && props.data.map((row, i) => {
            return (
            <>
            <tr key={i}>
            {
              row.rows.map((cell, j) => {
              return(
                <>
                {
                  !cell.hide &&
                  <td onClick={(e) => handleActionRowClick(e, i, j)} key={j}>

                  {cell.button && <Button size={'small'} className={'theme-table-btn'} variant={'outlined'}>{cell.buttonLabel}</Button>}
                  {!cell.button && cell.data}

                  </td>
                }
                </>
              )
              })
            }
            </tr>
            </>
          )
          })}
      </tbody>
    </table>
    </>
  )
}

export default Table;
