import React, {useState} from 'react';
import './TeacherForm.css';
import Form from '../../../../components/core/form/Form';
import { createTeacher } from '../../../../api';

export const TeacherForm = ({handleClose}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [teacherErr, setTeacherErr] = useState('')
  const [teacherForm, setTeacherForm] = useState({
    teacherName: '',
    teacherEmail: '',
    teacherContactNo: '',
    teacherZoomURL: ''
  })

  const setTeacher = (e) => {
    let form = teacherForm;
    for (let key in form) {
      if(key === e.target.id) {
        form[key] = e.target.value
      }
    }
    setTeacherForm(form);
  }

  const submitTeacher = (e) => {
    e.preventDefault();
    for (let key in teacherForm) {
      if(!teacherForm[key]) {
        setTeacherErr(key + ' is required!')
        return;
      }
    }

    setIsLoading(true);

    let teacherData = {
      name: teacherForm.teacherName,
      email: teacherForm.teacherEmail,
      contactNumber: teacherForm.teacherContactNo,
      zoomURL: teacherForm.teacherZoomURL
    }

    console.log(teacherData)

    createTeacher(teacherData)
    .then(res => {
      if(res.success) {
        setIsLoading(false)
        handleClose()
        console.log(res);
      } else {
        setIsLoading(false)
        setTeacherErr('Something went wrong!')
      }
    })
    .catch(err => {
      console.log(err);
    })
  }

  const TEACHER_FORM_VALUES = [
    {
      id: 'teacherName',
      label: 'Teacher Name',
      type: 'text',
      column: 1
    },
    {
      id: 'teacherEmail',
      label: 'Email',
      type: 'text',
      column: 2
    },
    {
      id: 'teacherContactNo',
      label: 'Contact No',
      type: 'text',
      column: 2
    },
    {
      id: 'teacherZoomURL',
      label: 'Zoom URL',
      type: 'text',
      column: 1
    }
  ]

  return (
    <Form
      submitTxt={'Add Teacher'}
      formGroups={TEACHER_FORM_VALUES}
      handleSubmit={submitTeacher}
      setFormValues={setTeacher}
      isLoading={isLoading}
      formErr={teacherErr}
    />
  )
  }


export default TeacherForm;
