import React, {useEffect, useState} from 'react';
import {Calendar as ReactCalendar} from 'react-calendar';
import {format, isWeekend} from 'date-fns';
import './RescheduleInvoiceLesson.css';

import 'react-calendar/dist/Calendar.css';
import { Button } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';




export const RescheduleInvoiceLesson = ({lessons, makeupLessons, handleRescheduledLessons, handleCancelledLessons}) => {
    const [value, onChange] = useState(null);
    const [clickedDate, setClickedDate] = useState(null)
    const [storeRescheduledDate, setStoreRescheduledDate] = useState(null)
    const [rescheduledDates, setRescheduledDates] = useState([]);
    const [cancelledDates, setCancelledDates] = useState([]);
    const [timeModalOpen, setTimeModalOpen] = useState(false)

    const formatDate = d => format(d, 'yyyy-MM-dd')

    useEffect(() => {
        handleRescheduledLessons(rescheduledDates)
    }, [rescheduledDates])

    useEffect(() => {
        console.log(cancelledDates.map(d => formatDate(d)))
        handleCancelledLessons(cancelledDates);
    }, [cancelledDates])
    

    const handleCalendarDayClick = (date) => {
        let dateToMatch = lessons.filter(d => formatDate(d) == formatDate(date)).pop()
        date.setHours(dateToMatch.getHours());
        date.setMinutes(dateToMatch.getMinutes());
        date.setSeconds(dateToMatch.getSeconds());
        if(cancelledDates.map(l => formatDate(l)).includes(formatDate(date))) {
            const indexOfCancelledDate = cancelledDates.map(d => JSON.stringify(d)).indexOf(JSON.stringify(date));
            setCancelledDates(c => c.filter((_, i) => i !== indexOfCancelledDate));
        } else {
            setClickedDate(date)
        }
    }

    const handleNewDateClick = (date) => {
        const newDate = new Date(date);
        newDate.setHours(clickedDate.getHours());
        newDate.setMinutes(clickedDate.getMinutes());
        newDate.setSeconds(clickedDate.getSeconds());
        newDate.setMilliseconds(clickedDate.getMilliseconds());
        setStoreRescheduledDate(newDate);
        setTimeModalOpen(true);
    }

    const handleSaveRescheduledDate = (inputtedTime) => {
        setStoreRescheduledDate(inputtedTime)
        setRescheduledDates(d => [...d, {old: clickedDate, new: inputtedTime}])
        setClickedDate(null);
        setTimeModalOpen(false)
    }

    const handleDayClick = (date) => {
      if (lessons.map(l => formatDate(l)).includes(formatDate(date))) {
        handleCalendarDayClick(date)
      } else if (rescheduledDates.map(l => formatDate(l.new)).includes(formatDate(date))) {
        setRescheduledDates(dt => dt.map(d => d.new).filter(d => d == date))      
    } else if (clickedDate && !makeupLessons.map(l => formatDate(l)).includes(formatDate(date))) {
        handleNewDateClick(date)
      }
    };

    const cancelLesson = () => {
        setCancelledDates(d => [...d, clickedDate])
        setClickedDate(null);
    }

  
    const tileClassName = ({ date, selectDate }) => {
        let classNames = '';
        if (makeupLessons.length > 0 && makeupLessons.map(l => formatDate(l)).includes(formatDate(date))) {
            classNames += ' makeup-tile'; // Apply custom class for blackout dates
        }
        if (cancelledDates.length > 0 && cancelledDates.map(l => formatDate(l)).includes(formatDate(date))) {
            classNames += ' cancelled-tile'; // Apply custom class for blackout dates
        }
        if (lessons.map(l => formatDate(l)).includes(formatDate(date))) {
            classNames += ' blackout-tile'; // Apply custom class for blackout dates
        }
        if (rescheduledDates.length > 0 && rescheduledDates.map(d => formatDate(d.old)).includes(formatDate(date))) {
            classNames += ' old-date-tile'; // Apply custom class for blackout dates
        }
        if (rescheduledDates.length > 0 && rescheduledDates.map(d => formatDate(d.new)).includes(formatDate(date))) {
            classNames += ' rescheduled-tile'; // Apply custom class for blackout dates
        }
        if (isWeekend(date)) {
            classNames += ' weekend-tile'; // Apply custom class for weekends
        }
        if (clickedDate && formatDate(date) === formatDate(clickedDate)) {
            classNames += ' clicked-tile'; // Apply custom class for clicked date
        }
        if (clickedDate) {
            classNames += ' hover-tile'
        }
      return classNames.trim(); // Return trimmed string
    };


    return (
    <div className={'pt-16 relative'}>
        {
            timeModalOpen &&
            <div className={'absolute z-[9] w-full h-full top-0 left-0 bg-white/100 flex justify-center items-center'}>
            <div>
            <p className={'font-sans max-w-96'}>Set the time of the rescheduled lesson below, or just click save to keep it at the same time as the original lesson</p>
            <div className={'w-full flex justify-center my-4'}>
            <TimePicker
              autoOk
              inputVariant={'outlined'}
            //   disablePast={true}
              minutesStep={5}
            //   format={' HH:mm BBBB, EEEE do MMMM yyyy'}
              ampm={false}
              value={storeRescheduledDate}
              onChange={(v) => handleSaveRescheduledDate(v)}
            />
            </div>
            <div className={'w-full flex justify-center'}>
            <button onClick={() => handleSaveRescheduledDate(storeRescheduledDate)} className={'font-sans w-48 py-2 rounded bg-blue-700 text-white'}>Save</button>
            </div>
            </div>
            </div>
        }
        <div className={'calendar-container'}>
            <ReactCalendar 
            onChange={onChange} 
            value={null} 
            tileClassName={tileClassName}
            onClickDay={handleDayClick}
            // tileDisabled={({date}) => blackoutDates.includes(format(date, 'yyyy-MM-dd')) }
            defaultActiveStartDate={lessons[0]} 
            />
            
        <div className={'flex justify-center my-8'}>
            {
                clickedDate &&
                <button className={'rounded bg-red-400 px-2 py-1 font-fredoka'} onClick={cancelLesson}>Cancel this lesson?</button>
            }
        </div>
        </div>
    </div>
    )
} 

export default RescheduleInvoiceLesson