import React, {useState} from 'react';
import Page from '../../../../../components/core/page/Page';
import Wrapper from '../../../../../components/core/wrapper/Wrapper';
import Card from '../../../../../components/core/card/Card';
import{ Button, Typography, TextField, Select, MenuItem} from '@material-ui/core';
import { getExerciseSentences } from '../../../../../api'
import {Check} from '@material-ui/icons';

import './GapFill.css';

export const GapFill = () => {

const [sentences, setSentences] = useState([]);
const [words, setWords] = useState([]);

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] =
    [array[randomIndex], array[currentIndex]];
  }
  return array;
}

const generateExercise = () => {
  getExerciseSentences({lessonID: "P1PR/1-1"})
    .then(results => {
      // console.log(results);
      let arr = []
      for(let i = 0; i < results.sentences.length / 8; i++) {
        let sentence = results.sentences[i * 8 + Math.floor(Math.random() * 6)]

        let sentenceArr = sentence.sentence.toLowerCase().split(sentence.word);
        sentence.sentenceA = sentenceArr[0].trim().charAt(0).toUpperCase() + sentenceArr[0].trim().slice(1)
        sentence.sentenceB = sentenceArr[1].trim()
        sentence.complete = false;
        arr.push(sentence)
      }
      setSentences(shuffle(arr))
      setWords(shuffle(arr.map(a => a.word)))
    })
}

const handleChange = (e, i, word) => {
    if(e.target.value == word) {
      let obj = sentences[i]
      obj.complete = true;
      setSentences(s => [...s.slice(0, i), obj, ...s.slice(i + 1)]
      )
    }
}

  return(
    <>
    <div className={'gap-fill-container'}>
    <div>
    { sentences.length == 0 &&
      <>
      <Typography variant={'h3'} align={'center'}>Welcome to gap fill!</Typography>
      <Button onClick={generateExercise} variant={'outlined'}>Start!</Button>
      </>
    }
    { sentences.length !== 0 &&
      <>
      <div className={'example-word-container'}>
      {
        words.map((word, i) => {
          return(
            <Typography variant={'h6'} className={'example-words'}>{word}</Typography>
          )
        })
      }
      </div>
      <div className={'exercise-sentences-container'}>
      {
      sentences.map((sentence, i) => {
        return(
          <>
            { !sentence.complete &&
              <Typography className={`example-sentences`} variant={'h5'}>
              { i + 1}. {sentence.sentenceA}
              <TextField onChange={(e) => handleChange(e, i, sentence.word)} className={'sentence-text-field'}/>
              {sentence.sentenceB}
              </Typography>
            }
            { sentence.complete &&
              <Typography className={`example-sentences correct`} variant={'h5'}>
              { i + 1}. {sentence.sentence} <Check />
              </Typography>
            }
          </>
        )
      })

      }
      </div>
      </>
    }
    </div>
    </div>
    </>
  )
}

export default GapFill
