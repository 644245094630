export const REPORT_FORM_TEMPLATE = [
  {
    id: 'teacherComment',
    label: "Teacher's Comment",
    type: 'text',
    column: 1,
    multiple: true
  },
  // {
  //   id: 'lessonRating',
  //   label: "Rate the lesson materials you were given for this lesson",
  //   type: 'button-group',
  //   column: 1,
  //   options: [
  //     {
  //       value: 1,
  //       label: '1'
  //     },
  //     {
  //       value: 2,
  //       label: '2'
  //     },
  //     {
  //       value: 3,
  //       label: '3'
  //     },
  //     {
  //       value: 4,
  //       label: '4'
  //     },
  //     {
  //       value: 5,
  //       label: '5'
  //     },
  //     {
  //       value: -1,
  //       label: 'No Rating'
  //     }
  //   ]
  // },
  {
    id: 'completedLessons',
    label: 'Which of the materials did you use / complete?',
    type: 'checkbox-table',
    column: 1,
    options: [
      {
        value: 'lesson-material-id',
        label: 'lesson-material-name',
      }
    ]
  },
  {
    id: 'nextLessonModule',
    label: 'Which lesson module will you cover next lesson?',
    type: 'select',
    column: 1,
    options: [
      {
        value: 'None',
        label: 'Module XX'
      }
    ]
  },
]
