import React, { useState, useEffect } from 'react';
import './RescheduleLesson.css';
import { Typography, Button, InputLabel, CircularProgress } from '@material-ui/core';
import {DateTimePicker} from '@material-ui/pickers';
import Card from '../../../../components/core/card/Card';
import { addMinutes } from 'date-fns';
import { rescheduleSingleLesson } from "../../../../api"

export const RescheduleLesson = ({lessonID, handleSuccess}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errMessage, setErrMessage] = useState(false)
  const [updatedDateTime, setUpdatedDateTime] = useState(null)

   const handleDateChange = (e) => {
     setUpdatedDateTime(e)
   }

   const confirmSelectedDateTime = () => {
     setIsLoading(true)
     const generateHongKongDateString = (d) => {
       let timezoneOffset = d.getTimezoneOffset();
       console.log(timezoneOffset)
       let arr = JSON.stringify(addMinutes(d, timezoneOffset - 2 * timezoneOffset)).split('.')
       return `${arr[0]}+08:00`.substring(1)
     }

     rescheduleSingleLesson(lessonID, {date: generateHongKongDateString(updatedDateTime)})
     .then(result => {
       if(result.success){
         setIsLoading(false)
         handleSuccess()
         console.log(result);
       } else {
         setIsLoading(false)
        setErrMessage(result.message)
       }
     }).catch(err => setErrMessage('Something went wrong...'))



   }

  return (
    <Card padding={'0px 24px 0px 24px'}>
        <>
        <InputLabel>Reschedule Lesson</InputLabel>
        <Typography variant={'h6'} className={"error-label"}>Important! Please set the reschedule time according to Hong Kong time (HKT)</Typography>
        <DateTimePicker
          autoOk
          inputVariant={'outlined'}
          disablePast={true}
          minutesStep={5}
          format={' HH:mm BBBB, EEEE do MMMM yyyy'}
          ampm={false}
          className={'reschedule-date-time'}
          value={updatedDateTime}
          onChange={(e) => handleDateChange(e)}
        />
      { !isLoading &&
        <Button onClick={confirmSelectedDateTime} className='confirm-date-time' variant={'outlined'}>OK</Button>
      }
      { isLoading &&
        <div style={{width: '100%', marginTop: '14px', display: 'flex', justifyContent: 'center'}}>
          <CircularProgress/>
        </div>
      }
      {
        errMessage && <Typography variant={'subtitle2'} color='red'>Something went wrong!</Typography>
      }
      </>
    </Card>
  )
}

export default RescheduleLesson
