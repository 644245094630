import React, {useState, useEffect, useContext} from 'react';
import './TeacherPortal.css';
import {Typography, Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core'
import Card from '../../components/core/card/Card';
import Page from '../../components/core/page/Page';
import Wrapper from '../../components/core/wrapper/Wrapper';
import Column from '../../components/core/column/Column';
import Modal from '../../components/core/modal/Modal';
import modalContext from "../../context/ModalContext";
import LessonCalendar from '../../components/lesson-calendar/LessonCalendar.js';
import {useHistory, useLocation} from 'react-router-dom'
import checkAuth from '../auth/auth-helpers.js';
import AddLessonReport from './add-lesson-report/AddLessonReport'
import LessonReports from './lesson-reports/LessonReports'
import LessonCalendarDay from './lesson-calendar-day/LessonCalendarDay'
import {getTeacherLessons, getSelectedLessonPlans, getTeacherById, getLessonReportsByTeacherID} from '../../api';
import {format} from 'date-fns'
import LessonTrials from './lesson-trials/LessonTrials.js';

export const TeacherPortal = () => {
  const history = useHistory();
  const location = useLocation();
  const { modalOpen, setModalOpen } = useContext(modalContext);
  const [ activeModalComponent, setActiveModalComponent] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [lessons, setLessons] = useState([])
  const [trials, setTrials] = useState([])
  const [lessonPlans, setLessonPlans] = useState([])
  const [lessonReports, setLessonReports] = useState([])
  const [activeLessonDate, setActiveLessonDate] = useState('')
  const [activeCalendarDate, setActiveCalendarDate] = useState('');
  const [activeLessonReport, setActiveLessonReport] = useState('')
  const [activeLessonPlan, setActiveLessonPlan] = useState('')
  const [teacherDetails, setTeacherDetails] = useState('')

  useEffect(() => {
      checkAuth(history)
      let tIdArr = location.pathname.split('/')
      const TEACHER_ID = tIdArr[tIdArr.length - 1]
      getTeacher(TEACHER_ID)
      getLessons(TEACHER_ID)
      getLessonReports(TEACHER_ID)
  }, [])

  useEffect(() => {
    setActiveLessonDate(Object.keys(lessons).sort()[0])
  }, [lessons])

  useEffect(() => {
    console.log(activeLessonDate);
  }, [activeLessonDate])

  const DAYSOFWEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  const SUBJECTMAP = {
    'Reading':'PR',
    'Speaking': 'SL',
    'Writing': 'GW'
  }

  const getTeacher = (teacherId) => {
    getTeacherById(teacherId).then(result => {
      setTeacherDetails(result.teacher)
    }).catch(err => {
      console.log(err)
    })
  }

  const getLessons = (teacherId) => {
    setIsLoading(true);
    getTeacherLessons(teacherId).then(result => {
      let datesByDay = {}
      result.customers.forEach((customer, i) => {
        result.invoices.forEach((invoice, j) => {
          if(customer._id == invoice.customerID) {
            result.invoices[j].customer = customer
          }
        })
      });

      result.invoices.forEach((invoice, i) => {
        result.lessons.forEach((lesson, j) => {
          if(invoice._id == lesson.customerID) {
            result.lessons[j].invoice = invoice
          }
        })
      });
      result.lessons.forEach((lesson, i) => {
        let dateTimeSplit = lesson.date.split('T');
        let lessonDate = dateTimeSplit[0]
        let lessonTime = dateTimeSplit[1]
        let currentDay = new Date(format(new Date(), 'yyyy-MM-dd')).getTime();
        let lessonDay = new Date(format(new Date(lessonDate), 'yyyy-MM-dd')).getTime();
        if(!datesByDay[lessonDate] && currentDay <= lessonDay) {
            datesByDay[lessonDate] = {lessons: []}
        }
        let invoice = result.invoices.filter(inv => inv._id == lesson.invoiceID)[0]
        let customer = result.customers.filter(cust => cust._id == invoice.customerID)[0]


        if(currentDay <= lessonDay) {
          datesByDay[lessonDate].lessons.push({
            date: lessonDate,
            dayOfMonth: format(new Date(lesson.date), 'dd'),
            dayOfWeek: format(new Date(lesson.date), 'eee'),
            time: format(new Date(lesson.date), 'HH:mm'),
            ampm: format(new Date(lesson.date), 'a'),
            student: customer.studentName,
            year: customer.studentYear,
            subject: invoice.lessonType.join(', '),
            module: '1',
            lesson: '2',
            isTrial: false
          })
        }
      });

      result.trials.forEach((trial) => {
        let dateTimeSplit = trial.date.split('T');
        let trialDate = dateTimeSplit[0]
        let trialTime = dateTimeSplit[1]
        let currentDay = new Date(format(new Date(), 'yyyy-MM-dd')).getTime();
        let trialDay = new Date(format(new Date(trialDate), 'yyyy-MM-dd')).getTime();
        if(!datesByDay[trialDate] && currentDay <= trialDay) {
            datesByDay[trialDate] = {lessons: []}
        }
        if(currentDay <= trialDay) {
          datesByDay[trialDate].lessons.push({
            date: trialDate,
            dayOfMonth: format(new Date(trial.date), 'dd'),
            time: format(new Date(trial.date), 'HH:mm'),
            ampm: format(new Date(trial.date), 'a'),
            dayOfWeek: format(new Date(trial.date), 'eee'),
            student: trial.studentName,
            year: trial.age,
            subject: 'Trial',
            module: null,
            lesson: null,
            isTrial: true
          })
        }
      })
      console.log(datesByDay)
      setLessons(datesByDay);
      setTrials(result.trials)
    })
  }

  const getLessonPlans = (year, subject, module, number) => {
    getSelectedLessonPlans({ageGroup: year, lessonType: subject, lessonModule: module, lessonNumber: number}).then(result => {
      // console.log(result);
      let lpArr = result.lessonplans
      let lmArr = result.lessonmaterials
      lpArr.forEach((lp, i) => {
        lmArr.forEach((lm, j) => {
          if(lm.lessonMaterialID == lp.lessonPlanID) {
            if(!lpArr[i].materials) {
              lpArr[i].materials = []
            }
            lpArr[i].materials.push(lm);
          }
        });
      });
      lpArr.sort((a, b) => a.lessonPlanID.localeCompare(b.lessonPlanID))
      setLessonPlans(lpArr);
    })
  }

  const getLessonReports = (teacherId) => {
    let reports = []
    getLessonReportsByTeacherID(teacherId).then(result => {
      result.customers.forEach((customer, i) => {
        result.invoices.forEach((invoice, j) => {
          if(customer._id == invoice.customerID) {
            result.invoices[j].customer = customer
          }
        })
      });

      result.invoices.forEach((invoice, i) => {
        result.lessons.forEach((lesson, j) => {
          if(invoice._id == lesson.customerID) {
            result.lessons[j].invoice = invoice
          }
        })
      });

      result.lessons.forEach((lesson, i) => {
        let invoice = result.invoices.filter(inv => inv._id == lesson.invoiceID)[0]
        let customer = result.customers.filter(cust => cust._id == invoice.customerID)[0]
        reports.push({
          id: lesson._id,
          invoiceID: lesson.invoiceID,
          teacherID: lesson.teacherID,
          customerID: lesson.customerID,
          date: new Date(lesson.date),
          student: customer.studentName,
          year: customer.studentYear,
          lessonPlanID: lesson.lessonPlanID,
          completedMaterials: lesson.completedMaterials.map(m => JSON.parse(m)),

        })
      });

      const sortedLessonReports = reports.sort((a, b) => a.date.getTime() > b.date.getTime() ? 1 : -1)
      setLessonReports(sortedLessonReports)
    })
  }

  const handleLessonSelect = (index) => {
    const selectedLesson = lessons[activeLessonDate].lessons[index]
    getLessonPlans(
      selectedLesson.year, SUBJECTMAP[selectedLesson.subject], selectedLesson.module, selectedLesson.lesson)
  }

  const handleLessonReportSelect = (report) => {
    setActiveLessonReport(report);
    setActiveModalComponent('report')
    setModalOpen(true)
  }

  const onLessonReportSubmit = () => {
    setModalOpen(false);
    let tIdArr = location.pathname.split('/')
    const TEACHER_ID = tIdArr[tIdArr.length - 1]
    getLessonReports(TEACHER_ID)
    setActiveLessonReport('')
  }

  const handleCalendarDayClick = (date) => {
    setActiveCalendarDate(date)
    setActiveModalComponent('calendar-date')
    setModalOpen(true)
  }

  const logout = () => {
    localStorage.removeItem('key');
    localStorage.removeItem('timestamp');
    history.push('/login')
  }

  return(
    <div className={'teacher-dashboard'}>
      <Page>
        <Wrapper>
          <Column cx={2}>
          { teacherDetails &&
            <Card margin={'7px 7px 7px 0'} classes={'teacher-card'} sx={{border: '1px solid #f00'}}>
              <div className={''}>
                <Typography variant={'h6'}>Welcome {teacherDetails.name}</Typography>
              </div>
            </Card>
          }
          {
            Object.keys(lessons).length > 0 && 
              <LessonCalendar lessons={Object.keys(lessons)} handleCalendarDayClick={handleCalendarDayClick} />
          }
          <Card margin={'7px 7px 7px 0'} sx={{border: '1px solid #f00'}}>
            <Typography variant={'h6'}>Upcoming Classes</Typography>
            <div className={'calendar-day-card-container'}>
            { Object.keys(lessons).length > 0 &&
              Object.keys(lessons).sort().slice(0, 5).map((key) => {
                return (
                  <div className={`calendar-day-card ${activeLessonDate == lessons[key].lessons[0].date ? 'active' : ''}`} onClick={() => setActiveLessonDate(key)}>
                    <Typography variant={'h6'} align={'center'} style={{marginBottom: '0px', lineHeight: '1.25rem'}}>
                      {lessons[key].lessons[0].dayOfMonth}
                    </Typography>
                    <Typography variant={'subtitle2'} align={'center'} style={{marginTop: '0px'}}>
                      {lessons[key].lessons[0].dayOfWeek}
                    </Typography>
                    {lessons[key].lessons.filter((l) => l.isTrial ? 1 : 0)[0] && <span className={'trial-indicator'}></span>}
                    <div className={'lesson-indicator-container'}>
                      <Typography variant={'caption'}>{lessons[key].lessons.length} Lesson{lessons[key].lessons.length > 1 && 's'}</Typography>
                      {
                       /* lessons[key].lessons.map(() => {
                          return (
                            <span className={'lesson-indicator'}></span>
                          )
                        }) */
                      }
                    </div>
                  </div>
                )
              })
            }
            </div>
            { lessons[activeLessonDate] &&
              lessons[activeLessonDate].lessons.map((lesson, i) => {
                return(
                  <div className={'lesson-card-container'}>
                  <Accordion onClick={() => handleLessonSelect(i)}>
                  <AccordionSummary>
                    <div className={'lesson-card-left-section'}>
                      <div className={'lesson-card-left-text-container'}>
                        <Typography variant={'h6'} align={'center'}>{lesson.time}</Typography>
                        <Typography variant={'subtitle2'} align={'center'}>{lesson.ampm}</Typography>
                      </div>
                    </div>
                    <div className={'lesson-card-right-section'}>
                      <div className={'lesson-card-right-text-container'}>
                        <Typography variant={'subtitle1'}>{lesson.student} - {lesson.year}</Typography>
                        <Typography variant={'subtitle2'} style={{color: lesson.isTrial ? '#f00': ''}}>{lesson.subject}</Typography>
                        {/*<Typography variant={'subtitle2'}>Module X.X</Typography>*/}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant={'subtitle2'}>Text.pdf</Typography>
                  </AccordionDetails>
                  </Accordion>
                  </div>
                )
              })
            }
          </Card>
          </Column>
          <Column cx={2}>
            <LessonTrials trials={trials} />
            <LessonReports lessonReports={lessonReports} handleLessonReportSelect={handleLessonReportSelect} />
          </Column>
        </Wrapper>
      </Page>
      { activeModalComponent == 'report' &&
        <Modal width={'768px'} component={
          <AddLessonReport lesson={activeLessonReport} onLessonReportComplete={onLessonReportSubmit}/> 
        }/>
      }
      { activeModalComponent == 'calendar-date' &&  activeCalendarDate &&
        <Modal width={'768px'} component={
          <LessonCalendarDay lessons={lessons[activeCalendarDate].lessons} /> 
        }/>
      }
    </div>
  )
}

export default TeacherPortal;
