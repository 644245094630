import React, {useContext, useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import './TeacherDashboard.css';
import TeacherForm from './teacher-form/TeacherForm';
import TeacherAvailability from './teacher-availability/TeacherAvailability';
import Page from '../../../components/core/page/Page';
import Wrapper from '../../../components/core/wrapper/Wrapper';
import Table from '../../../components/core/table/Table';
import Card from '../../../components/core/card/Card';
import Column from '../../../components/core/column/Column';
import Modal from '../../../components/core/modal/Modal';
import modalContext from "../../../context/ModalContext";
import {getAllTeachers} from "../../../api"
import {Button, Box, Typography} from '@material-ui/core';
import checkAuth from '../../auth/auth-helpers.js';

export const TeacherDashboard = (props) => {
  const history = useHistory();
  const { modalOpen, setModalOpen } = useContext(modalContext);
  const [modalMode, setModalMode] = useState(null)
  const [teacherID, setTeacherID] = useState(null)
  const [teacherTableHeader, setTeacherTableHeader] = useState(null)
  const [teacherTableData, setTeacherTableData] = useState(null)
  useEffect(() => {
    checkAuth(history)
    getTeachers()
  }, [])

  const getTeachers = () => {
    getAllTeachers().then(result => {
      function camelToString(s) {
          return s.split(/(?=[A-Z])/).map(function(p) {
              return p.charAt(0).toUpperCase() + p.slice(1);
          }).join(' ');
      }

      let teacherHeaderData = []
      for(let key in result.teachers[0]) {
        teacherHeaderData.push({data: camelToString(key), value: key})
      }
      teacherHeaderData.push({data: 'Availability', value: 'availability'})
      teacherHeaderData.push({data: 'Portal', value: 'portal'})
      teacherHeaderData[0].hide = true
      setTeacherTableHeader(teacherHeaderData)
      let teacherTableData = []
      result.teachers.forEach((teacher, i) => {
        let teacherTableRowData = []
        for(let key in teacher) {
          if (key == 'zoomURL') {
           teacherTableRowData.push({data: teacher[key], value: key, button: true, buttonLabel: 'Copy URL'})
         } else {
           teacherTableRowData.push({data: teacher[key], value: key})
         }
        }
        teacherTableRowData.push({data: teacher['_id'], value: 'availability', button: true, buttonLabel: 'Schedule'})
        teacherTableRowData.push({data: teacher['_id'], value: 'portal', button: true, buttonLabel: 'Portal'})
        teacherTableRowData[0].hide = true
        let rowData = {
          rows: teacherTableRowData
        }
        teacherTableData.push(rowData);
      })
      setTeacherTableData(teacherTableData);
    }).catch(err => {
      console.log(err)
    })
  }

  const handleAvailabilityOpen = () => {
    setModalMode('availability')
    setModalOpen(true)

  }

  const handleAvailabilityClosed = () => {

  }

  const handleNewTeacherOpen = () => {
    setModalMode('teacher')
    setModalOpen(true)
  }

  const handleNewTeacherClose = () => {
    setModalOpen(false)
  }

  const handleActionRowClick = (target, row, cell) => {
    const selectedID = teacherTableData[row].rows[cell].value
    if(selectedID == 'availability') {
      setModalMode('availability')
      setModalOpen(true)
      setTeacherID(teacherTableData[row].rows[cell].data)
    }
    if(selectedID == 'portal') {
      history.push(`/teacher/dashboard/${teacherTableData[row].rows[cell].data}`);
    }
  }

  return (
    <>
      <Page>
        <Wrapper>
          <Card>
            <div className='table-controls-container'>
              <Column cx={3}>
                <Box style={{padding: '0 14px 7px 0',boxSizing:'border-box'}}>
                <Button disabled={true} onClick={() => handleAvailabilityOpen()} variant="outlined" align="center" className='btn-theme' type='submit'>Find Availability</Button>
                </Box>
              </Column>
              <Column cx={3}>
                <Box style={{padding: '0 7px 7px 7px',boxSizing:'border-box'}}>
                </Box>
              </Column>
              <Column cx={3}>
                <Box style={{padding: '0 0 7px 14px',boxSizing:'border-box'}}>
                <Button onClick={() => handleNewTeacherOpen()} variant="outlined" align="center" className='btn-theme' type='submit'>Add New Teacher</Button>
                </Box>
              </Column>
            </div>
            <Table onActionRowClick={handleActionRowClick} headers={teacherTableHeader} data={teacherTableData} />
          </Card>
        </Wrapper>
      </Page>
      <Modal width={'992px'} component={
          <>
            { modalMode == 'teacher' &&
              <>
                <Box style={{padding: '14px 0 7px 14px',boxSizing:'border-box'}}>
                <Typography variant={'h6'}>Add new teacher...</Typography>
                </Box>
                <TeacherForm handleClose={handleNewTeacherClose} />
              </>
            }
            { modalMode == 'availability' && <TeacherAvailability teacherID={teacherID}/> }
          </>
        }/>
    </>
  )
}

export default TeacherDashboard;
