import React, {useContext, useState, useEffect} from 'react';
import './CustomerDashboard.css';
import {Link, useHistory} from 'react-router-dom';
import Page from '../../../components/core/page/Page';
import Wrapper from '../../../components/core/wrapper/Wrapper';
import Table from '../../../components/core/table/Table';
import Card from '../../../components/core/card/Card';
import Column from '../../../components/core/column/Column';
import Modal from '../../../components/core/modal/Modal';
import modalContext from "../../../context/ModalContext";
import {getAllCustomers} from "../../../api"
import {Button, Box, Typography} from '@material-ui/core';
import checkAuth from '../../auth/auth-helpers.js';

export const InvoiceDashboard = () => {
  const history = useHistory();
  const { modalOpen, setModalOpen } = useContext(modalContext);
  const [customerTableHeader, setCustomerTableHeader] = useState(null)
  const [customerTableData, setCustomerTableData] = useState(null)

  useEffect(() => {
    checkAuth(history)
    getCustomers()
  }, [])

  const getCustomers = () => {
    getAllCustomers().then(result => {
      function camelToString(s) {
          return s.split(/(?=[A-Z])/).map(function(p) {
              return p.charAt(0).toUpperCase() + p.slice(1);
          }).join(' ');
      }

      let customerHeaderData = []
      for(let key in result.customers[0]) {
        customerHeaderData.push({data: camelToString(key), value: key})
      }
      customerHeaderData.push({data: 'Portal', value: 'portal'})
      customerHeaderData[0].hide = true
      setCustomerTableHeader(customerHeaderData)

      let customerTableData = []
      result.customers.forEach((customer, i) => {
        let customerTableRowData = []
        for(let key in customer) {
          customerTableRowData.push({data: customer[key], value: key})
        }
        customerTableRowData.push({data: customer['_id'], value: 'portal', button: true, buttonLabel: 'Portal'})
        customerTableRowData[0].hide = true
        let rowData = {
          rows: customerTableRowData
        }
        customerTableData.push(rowData);
      })
      setCustomerTableData(customerTableData);
    }).catch(err => {
      console.log(err)
    })
  }

  const handleActionRowClick = (target, row, cell) => {
    const selectedID = customerTableData[row].rows[cell].values
    console.log(selectedID)
    if(selectedID == 'portal') {
      history.push(`/parent/dashboard/${customerTableData[row].rows[cell].data}`);
    }
  }

  return (
    <>
      <Page>
        <Wrapper>
          <Card>
            <div className='table-controls-container'>
              <Column cx={3}>
                <Box style={{padding: '0 14px 7px 0',boxSizing:'border-box'}}>
                </Box>
              </Column>
              <Column cx={3}>
                <Box style={{padding: '0 7px 7px 7px',boxSizing:'border-box'}}>
                </Box>
              </Column>
              <Column cx={3}>
                <Box style={{padding: '0 0 7px 14px',boxSizing:'border-box'}}>
                <Link to={'/generate-invoice'}><Button variant="outlined" align="center" className='btn-theme' type='submit'>Add New Customer</Button></Link>
                </Box>
              </Column>
            </div>
            <Table onActionRowClick={handleActionRowClick} headers={customerTableHeader} data={customerTableData} />
          </Card>
        </Wrapper>
      </Page>
    </>
  )
}
export default InvoiceDashboard;
