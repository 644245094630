import React from 'react';
import './LabsDashboard.css';
import {Link} from 'react-router-dom'
import Page from '../../../components/core/page/Page';
import Wrapper from '../../../components/core/wrapper/Wrapper';
import{ Button} from '@material-ui/core';

export const LabsDashboard = () => {
  return (
    <Page>
      <Wrapper>
        <Link to={'/gpt3-generator'}><Button variant={'outlined'} className={'dashboard-btn'}>Test</Button></Link>
      </Wrapper>
    </Page>
  )
}

export default LabsDashboard;
