import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import './Login.css';
import LoginJsx from './Login.jsx';
import checkAuth from '../auth-helpers.js';

import axios from 'axios';

function Login() {

  const [username, setUsername] = useState('');
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [authErr, setAuthErr] = useState('');
  const [loginErr, setLoginErr] = useState('');
  const [fadedLeft, setFadedLeft] = useState(true)
  const [fadedRight, setFadedRight] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setFadedLeft(true);
    setTimeout(function () {
    setFadedLeft(false)
  }, 100);
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username) {
      setLoginErr("Email is required");
      return;
    }
    if (!password) {
      setLoginErr("Password is required");
      return;
    }
    let data = {
      email: username,
      password: password
    }
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}auth/login`, data)
      .then(res => {
        console.log(res);
        setIsLoading(false);
        if(res.status === 200) {
          setFadedRight(true);
          let timestamp = Date.now();
          localStorage.setItem('key', res.data.token);
          localStorage.setItem('timestamp', timestamp);
          setTimeout(function () {
            history.push(`/dashboard`);
          }, 500);
        }
      }).catch(err => {
        setIsLoading(false);
        console.log(err)
        setLoginErr(err?.response?.data?.message)
      })
  }

  const setFormValues = (e) => {
    if (e.target.id === 'loginEmail') {
      setUsername(e.target.value);
      setUsernameTouched(true);
    }
    if (e.target.id === 'loginPassword') {
      setPassword(e.target.value);
      setPasswordTouched(true);
    }
  }

  useEffect(() => {
    checkAuth(history, location)
  }, [])

  return(
    <div>
      <LoginJsx
        handleSubmit={handleSubmit}
        setFormValues={setFormValues}
        isLoading={isLoading}
        loginErr={loginErr}
        fadedLeft={fadedLeft}
        fadedRight={fadedRight}
      />
    </div>
  )
}

export default Login;
