import React, {useState, useEffect} from 'react';
import {Calendar as ReactCalendar} from 'react-calendar';
import {format, isWeekend} from 'date-fns';
import './LessonCalendar.css';
import 'react-calendar/dist/Calendar.css';

export const LessonCalendar = ({lessons, handleCalendarDayClick}) => {
  const [value, onChange] = useState(null);

  const handleDayClick = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    if (lessons.includes(formattedDate)) {
      handleCalendarDayClick(formattedDate)
    }
  };

  const tileClassName = ({ date }) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    let classNames = '';
    if (lessons.includes(formattedDate)) {
      classNames += ' blackout-tile'; // Apply custom class for blackout dates
    }
    if (isWeekend(date)) {
      classNames += ' weekend-tile'; // Apply custom class for weekends
    }
    return classNames.trim(); // Return trimmed string
  };

  

    return (
      <>
      <div className={'calendar-container'}>
        <ReactCalendar 
          onChange={onChange} 
          value={null} 
          tileClassName={tileClassName}
          onClickDay={handleDayClick}
          // tileDisabled={({date}) => blackoutDates.includes(format(date, 'yyyy-MM-dd')) } 
        />
      </div>
      </>
    )
}

export default LessonCalendar;