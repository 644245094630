import React from "react";
import Card from '../../../components/core/card/Card';
import {Typography} from '@material-ui/core'
import {format} from 'date-fns'

export const LessonReports = ({lessonReports, handleLessonReportSelect}) => {
    return (
        <Card margin={'7px 7px 7px 7px'}>
            <Typography>You have {lessonReports.length} incomplete student reports!</Typography>
            <div className={'report-table-container'}>
                <table className={'report-table'}>
                {lessonReports.map((report) => {
                return (
                    <tr>
                        <td>{report.student}</td>
                        <td>{report.year}</td>
                        <td>{format(report.date, 'HH:mm, dd MMM')}</td>
                        <td>{report.lessonPlanID.length !== 0 ? report.lessonPlanID.join(' | ') : '-'}</td>
                        <td><button onClick={() => handleLessonReportSelect(report)}>Edit Report</button></td>
                    </tr>
                )
                })}
                </table>
            </div>
        </Card>
    )
}

export default LessonReports;
