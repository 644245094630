import { format } from 'date-fns';

export function checkAuth(history, location) {
  let key = localStorage.getItem('key');
  let timestamp = parseInt(localStorage.getItem('timestamp'));
  if(key && timestamp){
    let currentTime = Date.now();
    let timeLimit = timestamp + 7 * 24 * 60 * 60 * 1000
    if (timeLimit > currentTime) {
      if(location) {
        console.log(location)
        if(location.pathname == '/' || location.pathname == '/login') {
          history.push(`/dashboard`);
        }
      }
    } else {
      history.push(`/login`)
    }
  } else {
    history.push('/login')
  }
  // console.log(key)
  // console.log(format(timestamp, 'hh:mmaaa, EEEE do MMMM yyyy'))
  // console.log(`Exceeded time limit? : ${timeLimit > currentTime}`)
  // console.log(format(currentTime, 'hh:mmaaa, EEEE do MMMM yyyy'))
  // console.log(format(timeLimit, 'hh:mmaaa, EEEE do MMMM yyyy'))

}

export default checkAuth
