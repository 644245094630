import React, {useState, useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import './LessonPlanDashboard.css';
import modalContext from "../../../context/ModalContext";

import {Page as PageWrapper} from '../../../components/core/page/Page';
import Wrapper from '../../../components/core/wrapper/Wrapper';
import Card from '../../../components/core/card/Card';
import Modal from '../../../components/core/modal/Modal';
import Column from '../../../components/core/column/Column';

import LessonPlanGPT3 from './lessonplan-gpt3/LessonPlanGPT3'

import {Button, Box, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, Select, MenuItem} from '@material-ui/core';
import {ToggleButtonGroup, ToggleButton} from '@material-ui/lab';
import checkAuth from '../../auth/auth-helpers.js';
import {getSelectedLessonPlans, addWordToLesson, getLessonWordBank } from "../../../api"

import { Document, Page, pdfjs } from 'react-pdf'

export const LessonPlanDashboard = () => {
  const history = useHistory();
  const { modalOpen, setModalOpen } = useContext(modalContext);
  const [activeYear, setActiveYear] = useState('P1')
  const [activeSubject, setActiveSubject] = useState('PR');
  const [activeModule, setActiveModule] = useState(1);
  const [activeFilepath, setActiveFilepath] = useState('')
  const [lessonPlans, setLessonPlans] = useState([])
  const [wordBankActive, setWordBankActive] = useState(false)

  const [numPages, setNumPages] = useState(null);
  const [activeAccordion, setActiveAccordion] = useState(-1)

  const [newWordToggle, setNewWordToggle] = useState(false);
  const [newWord, setNewWord] = useState('')
  const [newWordClass, setNewWordClass] = useState('')
  const [activeWord, setActiveWord] = useState('')
  const [activeLessonID, setActiveLessonID] = useState('')

  const [lessonWordBank, setLessonWordBank] = useState([])


  const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


  useEffect(() => {
    checkAuth(history)
    getLessonPlans(activeYear, activeSubject, activeModule)
  }, [])

  const getLessonPlans = (year, subject, module) => {
    getSelectedLessonPlans({ageGroup: year, lessonType: subject, lessonModule: module}).then(result => {
      console.log(result);
      let lpArr = result.lessonplans
      let lmArr = result.lessonmaterials
      lpArr.forEach((lp, i) => {
        lmArr.forEach((lm, j) => {
          if(lm.lessonMaterialID == lp.lessonPlanID) {
            if(!lpArr[i].materials) {
              lpArr[i].materials = []
            }
            lpArr[i].materials.push(lm);
          }
        });
      });
      lpArr.sort((a, b) => a.lessonPlanID.localeCompare(b.lessonPlanID))
      setLessonPlans(lpArr);
    })
  }

  const handleToggleAgeChange = (e,value, i) => {
    if(!e.target.id && !e.target.value) {
      e.target = e.target.parentNode
    }
    setActiveYear(value)
    setActiveAccordion(-1);
    setActiveFilepath('')
    getLessonPlans(value, activeSubject, activeModule)
  }

  const handleToggleSubjectChange = (e,value, i) => {
    if(!e.target.id && !e.target.value) {
      e.target = e.target.parentNode
    }
    setActiveSubject(value)
    setActiveAccordion(-1);
    setActiveFilepath('')
    getLessonPlans(activeYear, value, activeModule)
  }

  const handleToggleModuleChange = (e,value, i) => {
    if(!e.target.id && !e.target.value) {
      e.target = e.target.parentNode
    }
    setActiveModule(value)
    setActiveAccordion(-1);
    setActiveFilepath('')
    getLessonPlans(activeYear, activeSubject, value)
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
  setNumPages(nextNumPages);
  }

  const handleToggleAccordion = (i) => {
    setActiveAccordion(i)
    setActiveFilepath(lessonPlans[i].lessonPlanURI)
    getLessonWordBank({lessonID: lessonPlans[i].lessonPlanID}).then(result => {
      setLessonWordBank(result.words)
    })
  }

  const handleNewWordToggle = () => {
    setNewWordToggle(true)
  }

  const handleNewWordChange = (e) => {
    setNewWord(e.target.value)
  }

  const handleNewWordClassChange = (e) => {
    setNewWordClass(e.target.value)
  }

  const handleSaveNewWord = (i) => {

    let wordData = {
      word: newWord,
      lessonID: lessonPlans[i].lessonPlanID,
      wordClass: newWordClass
    }

    addWordToLesson(wordData)
    .then(result => {
      console.log(result);
      setNewWordToggle(false)
      getLessonWordBank({lessonID: lessonPlans[i].lessonPlanID}).then(result => {
        setLessonWordBank(result.words)
      })
    })
  }

  const openWordModal = (word, i) => {
    setActiveWord(word)
    setActiveLessonID(lessonPlans[i].lessonPlanID)
    setModalOpen(true)
  }

  return (
    <>
      <PageWrapper>
        <Wrapper classes={'lesson-plan-dashboard'}>
          <Card>
            <Column cx={1}>
            <Typography variant={'subtitle2'}><b>Age Group</b></Typography>
            <div style={{width: '50%', display: 'flex'}}>
              <ToggleButtonGroup
                id={'ageToggle'}
                color={"primary"}
                default={'P1'}
                value={activeYear}
                exclusive
                onChange={(e,v) => handleToggleAgeChange(e,v)}>
                  <ToggleButton disabled id={'K1'} value={'K1'}>K1</ToggleButton>
                  <ToggleButton disabled id={'K2'} value={'K2'}>K2</ToggleButton>
                  <ToggleButton disabled id={'K3'} value={'K3'}>K3</ToggleButton>
                  <ToggleButton id={'P1'} value={'P1'}>P1</ToggleButton>
                  <ToggleButton id={'P2'} value={'P2'}>P2</ToggleButton>
                  <ToggleButton disabled id={'P3'} value={'P3'}>P3</ToggleButton>
                  <ToggleButton disabled id={'P4'} value={'P4'}>P4</ToggleButton>
                  <ToggleButton disabled id={'P5'} value={'P5'}>P5</ToggleButton>
                  <ToggleButton disabled id={'P6'} value={'P6'}>P6</ToggleButton>
              </ToggleButtonGroup>
            </div>
            </Column>
            <Column cx={2}>
              <Column cx={2}>
              <Typography variant={'subtitle2'}>Subject</Typography>
              <ToggleButtonGroup
                id={'subjectToggle'}
                color={"primary"}
                default={'PR'}
                value={activeSubject}
                exclusive
                className={'toggle-group-smaller-font'}
                onChange={(e,v) => handleToggleSubjectChange(e,v)}>
                  <ToggleButton id={'PR'} value={'PR'}>Reading</ToggleButton>
                  <ToggleButton id={'SL'} value={'SL'}>Speaking</ToggleButton>
                  <ToggleButton id={'GW'} value={'GW'}>Grammar</ToggleButton>
              </ToggleButtonGroup>
              </Column>
              <Column cx={2}>
              <Typography variant={'subtitle2'}>Module</Typography>
              <ToggleButtonGroup
                id={'moduleToggle'}
                color={"primary"}
                default={1}
                value={activeModule}
                exclusive
                onChange={(e,v) => handleToggleModuleChange(e,v)}>
                  <ToggleButton id={1} value={1}>1</ToggleButton>
                  <ToggleButton id={2} value={2}>2</ToggleButton>
                  <ToggleButton disabled id={3} value={3}>3</ToggleButton>
                  <ToggleButton disabled id={4} value={4}>4</ToggleButton>
                  <ToggleButton disabled id={5} value={5}>5</ToggleButton>
                  <ToggleButton disabled id={6} value={6}>6</ToggleButton>
              </ToggleButtonGroup>
              </Column>

            <div className={'accordion-container'}>
            {
              lessonPlans.map((lp, i) => {
                return (

                  <Accordion expanded={activeAccordion == i}>
                    <AccordionSummary onClick={() => handleToggleAccordion(i)}>
                      <Typography style={{paddingLeft: '12px'}} variant={'subtitle1'}>Lesson {lp.lessonModule}.{lp.lessonNumber}{lp.revision ? 'R' : ''}</Typography>
                      <p onClick={() => setWordBankActive(!wordBankActive)} className='word-bank-btn' role="button">{!wordBankActive ? 'Word Bank' : 'Lesson Plans'}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                    { !wordBankActive &&
                      <>
                      <Typography variant={'subtitle1'} onClick={() => setActiveFilepath(lp.lessonPlanURI)}>{lp.lessonPlanName}</Typography>
                      { lp.materials &&
                        lp.materials.map((lm, j) => {
                          return (
                            <>{lp.lessonPlanID == lm.lessonMaterialID && <Typography onClick={() => setActiveFilepath(lm.lessonMaterialURI)} variant={'subtitle1'}>{lm.lessonMaterialName}</Typography>}</>
                          )
                        })
                      }
                      </>
                    }
                    { wordBankActive &&
                      <>
                      <div style={{margin: '14px 0'}}>
                      { lessonWordBank.length !== 0 &&

                        lessonWordBank.map(word => {
                          return (
                            <p onClick={() => openWordModal(word.word, i)} className={`word-bank-word word-bank-${word.wordClass}`}>{word.word}</p>
                          )
                        })
                      }
                      {
                        lessonWordBank.length == 0 &&
                        <p className={'word-bank-info-msg'}>- There are no words in this lesson's word bank -</p>
                      }
                      </div>
                      { !newWordToggle && <Button className={'add-new-word-btn'} onClick={handleNewWordToggle} variant={'outlined'}>+ Add New Word</Button>}
                      { newWordToggle &&
                        <>
                        <TextField onChange={(e) => handleNewWordChange(e)} className={'add-new-word-input'} variant={'outlined'}></TextField>
                        <Select onChange={(e) => handleNewWordClassChange(e)} className={'word-bank-select'} variant={'outlined'}>
                          <MenuItem value={'adjective'}>Adjective</MenuItem>
                          <MenuItem value={'noun'}>Noun</MenuItem>
                          <MenuItem value={'verb'}>Verb</MenuItem>
                        </Select>
                        <Button onClick={() => handleSaveNewWord(i)} variant={'outlined'}>Save</Button>
                        </>
                      }
                      </>
                    }
                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
            </div>
            </Column>
            <Column cx={2}>
              {
                activeFilepath &&
                <div className={'pdf-reader-container'}>
                <Document file={`${encodeURI('http://localhost:3300/' + activeFilepath)}`}
                   onLoadSuccess={onDocumentLoadSuccess} options={options}
                   >
                    {
                      Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                      ))
                    }
                </Document>

              </div>
            }
            </Column>
          </Card>
        </Wrapper>
      </PageWrapper>
      <Modal width={'992px'} component={
          <>
            <LessonPlanGPT3 word={activeWord} age={activeYear} lessonID={activeLessonID}/>
          </>
        }/>
    </>
  )
}
export default LessonPlanDashboard;
