import React, {useState, useEffect, useContext} from 'react';
import './StudentPortal.css'
import {Typography, Button, Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core'
import Card from '../../components/core/card/Card';
import Page from '../../components/core/page/Page';
import Wrapper from '../../components/core/wrapper/Wrapper';
import Column from '../../components/core/column/Column';
import Modal from '../../components/core/modal/Modal';
import modalContext from "../../context/ModalContext";
import {Link, useHistory, useLocation} from 'react-router-dom'
import checkAuth from '../auth/auth-helpers.js';
import {format} from 'date-fns'
import { getNextCustomerLessons, getUpcomingCustomerInvoiceLessons } from '../../api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StudentPortal = () => {
  const history = useHistory();
  const location = useLocation();
  const { modalOpen, setModalOpen } = useContext(modalContext);
  const [isLoading, setIsLoading] = useState(false)
  const [lessons, setLessons] = useState([])

useEffect(() => {
    checkAuth(history)
    let tIdArr = location.pathname.split('/')
    const STUDENT_ID = tIdArr[tIdArr.length - 1]
    getNextClientLessons(STUDENT_ID)
    // getLessons(STUDENT_ID)
    // getLessonReports(STUDENT_ID)
}, [])

const getNextClientLessons = (id) => {
  getNextCustomerLessons(id).then(result => {
    console.log(result)
  })
}

return (
  <div className={'student-dashboard'}>
  <Page>
    <Wrapper>
    <Column cx={2}>
    <div className={'student-dashboard-nav'}>
      <div className={'student-dashboard-menu-btn'}>
      </div>
      <div className={'student-dashboard-notifications-btn'}>
      </div>
    </div>
    <Card>
      <div className={'student-dashboard-next-lesson-header'}>
        <Column cx={2} classes={'column-vertical-align-center'}>
          <Typography align={'center'} variant={'h6'}>Next Lesson </Typography>
        </Column>
        <Column cx={2} classes={'column-vertical-align-center'}>
          <Typography variant={'h5'}>11:00AM</Typography>
          <Typography variant={'subtitle2'}>14th January</Typography>
        </Column>
      <div style={{paddingTop: '11px', width: '100%', display: 'flex', justifyContent: 'center'}}>
        <Button variant={'outlined'}>Mr Jack's Classroom Link</Button>
      </div>
      </div>
      <div className={'student-dashboard-next-lesson-materials'}>
        <table className={'student-dashboard-next-lesson-materials-table'}>
          <tr>
            <td>Ollie's School Day - Test Material.pdf</td>
            <td><Button className={'student-dashboard-next-lesson-materials-btn'} variant={'outlined'} size={'small'}>Print</Button></td>
          </tr>
          <tr>
            <td>Ollie's School Day - Test Material.pdf</td>
            <td><Button className={'student-dashboard-next-lesson-materials-btn'} variant={'outlined'} size={'small'}>Print</Button></td>
          </tr>
          <tr>
            <td>Ollie's School Day - Test Material.pdf</td>
            <td><Button className={'student-dashboard-next-lesson-materials-btn'} variant={'outlined'} size={'small'}><FontAwesomeIcon icon="fa-solid fa-print" /> Print</Button></td>
          </tr>
        </table>
      </div>
    </Card>
    <Card>
      <Typography variant={'subtitle1'}>Your Upcoming Lessons</Typography>
      <div className={'student-dashboard-schedule-container'}>
        <table className={'student-dashboard-schedule-table'}>
          <tr>
            <td>14th Sept, Wed 11:00AM</td>
            <td><Button className={'student-dashboard-schedule-btn'} variant={'outlined'} size={'small'}>Options</Button></td>
          </tr>
          <tr>
            <td>21st Sept, Wed 11:00AM</td>
            <td><Button className={'student-dashboard-schedule-btn'} variant={'outlined'} size={'small'}>Options</Button></td>
          </tr>
          <tr>
            <td>28th Sept, Wed 11:00AM</td>
            <td><Button className={'student-dashboard-schedule-btn'} variant={'outlined'} size={'small'}>Options</Button></td>
          </tr>
        </table>
      </div>
    </Card>
    {/* <BigButton /> */}
    </Column>
    <Column cx={2}>
    </Column>
    </Wrapper>
  </Page>
  </div>
)
}

export default StudentPortal;
