import React, {useState, useEffect} from 'react';
import './LessonPlanGPT3.css';
import {Link} from 'react-router-dom'
import Page from '../../../../components/core/page/Page';
import Wrapper from '../../../../components/core/wrapper/Wrapper';
import Card from '../../../../components/core/card/Card';
import Column from '../../../../components/core/column/Column';
import{ Button, Typography, TextField, Select, MenuItem} from '@material-ui/core';
import { generateGPT3ExampleSentences, getWordBankSentences, getWordBankImages, regenerateGPT3Sentence, uploadWordBankImage } from '../../../../api';
import {Refresh, Edit} from '@material-ui/icons';

export const LessonPlanGPT3 = ({word, age, lessonID}) => {
  const [returnedSentences, setReturnedSentences] = useState([])
    const [returnedImages, setReturnedImages] = useState([])
  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedImageName, setSelectedImageName] = useState(null)

  const AGE_MAP = {
    P1: 5,
    P2: 6,
    P3: 7,
    P4: 8,
    P5: 9,
    P6: 10,
  }

  useEffect(() => {
    getSentences()
    getImages()
  }, [word])

  const getSentences = () => {
    getWordBankSentences({word: word, lessonID: lessonID})
      .then(res => {
        setReturnedSentences(res.sentences.sort((a, b) => (a.index > b.index) ? 1 : -1));
      })
  }

  const getImages = () => {
    getWordBankImages({word: word, lessonID: lessonID})
      .then(res => {
        setReturnedImages(res.images);
      })
  }

  const generateSentence = () => {
    generateGPT3ExampleSentences({
      prompt: word,
      lessonID: lessonID,
      age: AGE_MAP[age]
    })
    .then(res => {
      if(res.success) {
        getSentences()
      }
    });
  }

  const regenerateSentence = (index) => {
    regenerateGPT3Sentence({id: returnedSentences[index]._id})
      .then(result => {
        getSentences()
      })
  }

  const uploadImage = async (e) => {
      const formData = new FormData();
      formData.append("file", selectedImage);
      formData.append("fileName", selectedImageName);
      formData.append("word", word);
      formData.append("lessonID", lessonID);
      try {
        const res = await uploadWordBankImage(formData)
        setSelectedImage(null)
        setSelectedImageName(null)
        getImages();
      } catch (ex) {
        console.log(ex);
      }
  }

  return (
    <>
    <Typography className={'gpt3-header'} variant={'h5'} style={{'marginTop': '14px'}} align={'center'}>{word}</Typography>
        { returnedSentences.length !== 0 &&
        <div className={'results-container'}>
        <table className='sentence-table'>
        <tbody>
          {
            returnedSentences.map((sentence, senti) => {
            return (
                <>
                { senti * 2 <= returnedSentences.length - 1 &&
                <tr>
                  <td>
                  {returnedSentences[senti * 2].sentence}
                  <span onClick={() => regenerateSentence(senti * 2)} className={'sentence-refresh-btn'} role="button"><Refresh /></span>
                  <span className={'sentence-edit-btn'} role="button"><Edit /></span>
                  </td>
                  <td>{returnedSentences[senti * 2 + 1].sentence}
                  <span onClick={() => regenerateSentence(senti * 2 + 1)} className={'sentence-refresh-btn'} role="button"><Refresh /></span>
                  <span className={'sentence-edit-btn'} role="button"><Edit /></span>
                  </td>
                </tr>
                }
                </>
            )
          })
        }
        </tbody>
        </table>
        { returnedImages.length !== 0 &&
          <div className="word-images-container" style={{marginTop: '14px'}}>
            {
              returnedImages.map((img) => {
                return (
                  <img src={img.filepath} />
                )
              })
            }
          </div>
        }
          </div>
        }
        { returnedSentences.length == 0 &&
          <>
          <Typography variant={'subtitle1'} className='empty-sentences-info'>There are no example sentences for this word yet</Typography>
          <table className='sentence-table' style={{'opacity': 0.2}}>
            <tbody>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          </>
        }
        { returnedSentences.length == 0 &&
          <div className="prompt-container" style={{marginTop: '14px'}}>
          <Button variant={'outlined'} onClick={generateSentence}>Generate Sentences For {age} Using {word}</Button>
          </div>
        }
        {selectedImage && (
          <div className='selected-image-container'>
            <div>
              <img alt="not fount" width={"420px"} src={URL.createObjectURL(selectedImage)} />
              <div style={{'display': 'flex', 'justifyContent': 'space-between'}}>
              <button className='selected-image-btn' onClick={()=>setSelectedImage(null)}>Remove</button>
              <button className='selected-image-btn' onClick={uploadImage}>Save</button>
              </div>
            </div>
          </div>
        )}
        <div className="prompt-container" style={{margin: '14px'}}>

        <Button variant={'outlined'} component={'label'}>Upload Image
          <input type="file" name="file" onChange={(e) => { setSelectedImage(e.target.files[0])}} hidden/>
        </Button>
        </div>
    </>
  )
}

export default LessonPlanGPT3;
