import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import './AdminDashboard.js';

import Page from '../../../components/core/page/Page';
import Wrapper from '../../../components/core/wrapper/Wrapper';
import Card from '../../../components/core/card/Card';
import Column from '../../../components/core/column/Column';

import {Button, Box, Typography, CircularProgress} from '@material-ui/core';
import checkAuth from '../../auth/auth-helpers.js';
import {syncToGoogleDrive, updateLessonsWithCustomerID} from "../../../api"

export const AdminDashboard = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    checkAuth(history)
  }, [])

  const syncToDrive = () => {
    setIsLoading(true)
    syncToGoogleDrive().then(result => {
      console.log(result);
      setIsLoading(false)
    })
  }

  const updateLessons = () => {
    setIsLoading(true)
    updateLessonsWithCustomerID().then(result => {
      console.log(result);
      setIsLoading(false)
    })
  }

  return (
    <>
      <Page>
        <Wrapper>
          <Card>
            <Column cx={2}>
              <Typography variant={'h6'}>Sync to Drive</Typography>
              <Typography variant={'subtitle1'} style={{marginTop: '4px'}}>Update local data direct from Google Drive: Updates Lesson Plans, Lesson Assessments & Other Documents</Typography>
              { !isLoading && <Button onClick={syncToDrive} className={'btn-theme-secondary btn-rounded'} style={{marginTop: '14px'}} variant={'outlined'}>Sync</Button>}
              { isLoading && <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><CircularProgress /></div>}
            </Column>
            <Column cx={2}>

            </Column>
          </Card>
        </Wrapper>
      </Page>
    </>
  )
}
export default AdminDashboard;
