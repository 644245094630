import { addMinutes } from "date-fns";

export const camelToString = s => {
          return s.split(/(?=[A-Z])/).map(function(p) {
              return p.charAt(0).toUpperCase() + p.slice(1);
          }).join(' ');
}

export const generateHongKongDateString = (d) => {
    let timezoneOffset = d.getTimezoneOffset();
    let arr = JSON.stringify(addMinutes(d, timezoneOffset - 2 * timezoneOffset)).split('.')
    return `${arr[0]}+08:00`.substring(1)
  }