export const AVAILABILITY_TEMPLATE = [
  {
    day: 'Monday',
    times: [
      {
        timeID: 14,
        available: false,
        lessons: []
      },
      {
        timeID: 15,
        available: false,
        lessons: []
      },
      {
        timeID: 16,
        available: false,
        lessons: []
      },
      {
        timeID: 17,
        available: false,
        lessons: []
      },
      {
        timeID: 18,
        available: false,
        lessons: []
      },
      {
        timeID: 19,
        available: false,
        lessons: []
      },
      {
        timeID: 20,
        available: false,
        lessons: []
      },
      {
        timeID: 21,
        available: false,
        lessons: []
      }
    ]
  },
  {
    day: 'Tuesday',
    times: [
      {
        timeID: 14,
        available: false,
        lessons: []
      },
      {
        timeID: 15,
        available: false,
        lessons: []
      },
      {
        timeID: 16,
        available: false,
        lessons: []
      },
      {
        timeID: 17,
        available: false,
        lessons: []
      },
      {
        timeID: 18,
        available: false,
        lessons: []
      },
      {
        timeID: 19,
        available: false,
        lessons: []
      },
      {
        timeID: 20,
        available: false,
        lessons: []
      },
      {
        timeID: 21,
        available: false,
        lessons: []
      }
    ]
  },
  {
    day: 'Wednesday',
    times: [
      {
        timeID: 14,
        available: false,
        lessons: []
      },
      {
        timeID: 15,
        available: false,
        lessons: []
      },
      {
        timeID: 16,
        available: false,
        lessons: []
      },
      {
        timeID: 17,
        available: false,
        lessons: []
      },
      {
        timeID: 18,
        available: false,
        lessons: []
      },
      {
        timeID: 19,
        available: false,
        lessons: []
      },
      {
        timeID: 20,
        available: false,
        lessons: []
      },
      {
        timeID: 21,
        available: false,
        lessons: []
      }
    ]
  },
  {
    day: 'Thursday',
    times: [
      {
        timeID: 14,
        available: false,
        lessons: []
      },
      {
        timeID: 15,
        available: false,
        lessons: []
      },
      {
        timeID: 16,
        available: false,
        lessons: []
      },
      {
        timeID: 17,
        available: false,
        lessons: []
      },
      {
        timeID: 18,
        available: false,
        lessons: []
      },
      {
        timeID: 19,
        available: false,
        lessons: []
      },
      {
        timeID: 20,
        available: false,
        lessons: []
      },
      {
        timeID: 21,
        available: false,
        lessons: []
      }
    ]
  },
  {
    day: 'Friday',
    times: [
      {
        timeID: 14,
        available: false,
        lessons: []
      },
      {
        timeID: 15,
        available: false,
        lessons: []
      },
      {
        timeID: 16,
        available: false,
        lessons: []
      },
      {
        timeID: 17,
        available: false,
        lessons: []
      },
      {
        timeID: 18,
        available: false,
        lessons: []
      },
      {
        timeID: 19,
        available: false,
        lessons: []
      },
      {
        timeID: 20,
        available: false,
        lessons: []
      },
      {
        timeID: 21,
        available: false,
        lessons: []
      }
    ]
  },
  {
    day: 'Saturday',
    times: [
      {
        timeID: 14,
        available: false,
        lessons: []
      },
      {
        timeID: 15,
        available: false,
        lessons: []
      },
      {
        timeID: 16,
        available: false,
        lessons: []
      },
      {
        timeID: 17,
        available: false,
        lessons: []
      },
      {
        timeID: 18,
        available: false,
        lessons: []
      },
      {
        timeID: 19,
        available: false,
        lessons: []
      },
      {
        timeID: 20,
        available: false,
        lessons: []
      },
      {
        timeID: 21,
        available: false,
        lessons: []
      }
    ]
  },
  {
    day: 'Sunday',
    times: [
      {
        timeID: 14,
        available: false,
        lessons: []
      },
      {
        timeID: 15,
        available: false,
        lessons: []
      },
      {
        timeID: 16,
        available: false,
        lessons: []
      },
      {
        timeID: 17,
        available: false,
        lessons: []
      },
      {
        timeID: 18,
        available: false,
        lessons: []
      },
      {
        timeID: 19,
        available: false,
        lessons: []
      },
      {
        timeID: 20,
        available: false,
        lessons: []
      },
      {
        timeID: 21,
        available: false,
        lessons: []
      }
    ]
  }
]
