import React, {useState, useEffect} from 'react';
import './Dashboard.css';
import {Typography, Button} from '@material-ui/core'
import Card from '../../components/core/card/Card';
import Page from '../../components/core/page/Page';
import Wrapper from '../../components/core/wrapper/Wrapper';
import {Link, useHistory} from 'react-router-dom'
import checkAuth from '../auth/auth-helpers.js';
import dashboard from '../../assets/images/magic-sprout-dashboard.jpg';

export const Dashboard = () => {
  const history = useHistory();
  const [randomBackground, setRandomBackground] = useState(0)
  useEffect(() => {
      checkAuth(history)
      const randomNum = Math.floor(Math.random() * 4)
      setRandomBackground(randomNum)
  }, [])

  const logout = () => {
    localStorage.removeItem('key');
    localStorage.removeItem('timestamp');
    history.push('/login')
  }

  return(
    <div className={'main-dashboard'}>
      <Page>
        <Wrapper sx={{height: '92vh',width: '420px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          {/*<img className='acid-mode rainbows' style={{position: 'absolute', left: 0, top: 0, zIndex: 0, height: '100vh', width: '100vw', borderTopLeftRadius: '20% 30%', borderTopRightRadius: '13% 36%', borderBottomLeftRadius: '30% 14%', borderBottomRightRadius: '50% 30%'}}
          src={dashboard} />*/}
          <div style={{position: 'fixed', left: 'calc(50% - 210px)', width: '420px'}}>
          <Card>
          <Typography style={{fontFamily: "Fredoka, sans-serif",letterSpacing: '8px', webkitTextStrokeWidth: '2px', webkitTextStrokeColor: '#aaa', textAlign: 'center', color: '#fff', textShadow: '0px 0px 4px #fff'}} variant={'h3'}>PORTAL</Typography>
            <br />
            <Link to={'/create-trial'}><Button variant={'outlined'} className={'dashboard-btn'}>Create Trial Lesson</Button></Link>
            <Link to={'/generate-invoice'}><Button variant={'outlined'} className={'dashboard-btn'}>Create an Invoice</Button></Link>
            <hr />
            <Link to={'/invoices'}><Button variant={'outlined'} className={'dashboard-btn'}>Trials & Invoices</Button></Link>
            <Link to={'/lesson-plans'}><Button variant={'outlined'} className={'dashboard-btn'}>Lesson Plans & Assessments</Button></Link>
            <Link to={'/teachers'}><Button variant={'outlined'} className={'dashboard-btn'}>Teachers</Button></Link>
            <Link to={'/parents'}><Button variant={'outlined'} className={'dashboard-btn'}>Clients</Button></Link>
          </Card>
          <Card>
          <Link to={'/labs'}><Button variant={'outlined'} className={'dashboard-btn'}>Labs</Button></Link>
            <Link to={'/settings'}><Button variant={'outlined'} className={'dashboard-btn'}>Settings</Button></Link>
            <Button onClick={logout} variant={'outlined'} className={'dashboard-btn'}>Logout</Button>
          </Card>
          </div>
          { randomBackground == 0 &&
            <svg style={{display: 'none'}}>
            <filter id='wavy'>
            <feTurbulence x="0" y="0" baseFrequency="1"
            numOctaves="1" seed="2">
            <animate attributeName='baseFrequency' dur='120s'
            values='0.02;0.05;0.02' repeatCount='indefinite'></animate>
            </feTurbulence>
            <feDisplacementMap in='SourceGraphic' scale='200'></feDisplacementMap>
            </filter>
            </svg>
          }
          { randomBackground == 1 &&
            <svg style={{display: 'none'}}>
            <filter id='wavy'>
            <feTurbulence x="0" y="0" baseFrequency="0.5"
            numOctaves="1" seed="2">
            <animate attributeName='baseFrequency' dur='120s'
            values='0.005;0.01;0.005' repeatCount='indefinite'></animate>
            </feTurbulence>
            <feDisplacementMap in='SourceGraphic' scale='200'></feDisplacementMap>
            </filter>
            </svg>
          }
          { randomBackground == 2 &&
            <svg style={{display: 'none'}}>
            <filter id='wavy'>
            <feTurbulence x="0" y="0" baseFrequency="0.05"
            numOctaves="1" seed="2">
            <animate attributeName='baseFrequency' dur='240s'
            values='0.05;0.1;0.05' repeatCount='indefinite'></animate>
            </feTurbulence>
            <feDisplacementMap in='SourceGraphic' scale='40'></feDisplacementMap>
            </filter>
            </svg>
          }
          { randomBackground == 3 &&
            <svg style={{display: 'none'}}>
            <filter id='wavy'>
            <feTurbulence x="0" y="0" baseFrequency="0.1"
            numOctaves="1" seed="2">
            <animate attributeName='baseFrequency' dur='240s'
            values='0.08;0.5;0.08' repeatCount='indefinite'></animate>
            </feTurbulence>
            <feDisplacementMap in='SourceGraphic' scale='40'></feDisplacementMap>
            </filter>
            </svg>
          }
        </Wrapper>
      </Page>
    </div>
  )
}

export default Dashboard;
