import React, {useState, useEffect} from 'react';
import './CustomerView.css';
import {getCustomerByID} from '../../../../api';
import {Typography} from '@material-ui/core';
import Column from '../../../../components/core/column/Column';
import Card from '../../../../components/core/card/Card';

export const CustomerView = ({customerID}) => {

  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    getCustomerView()
  }, [customerID])

  const camelToString = (s) => {
    return s.split(/(?=[A-Z])/).map(function(p) {
        return p.charAt(0).toUpperCase() + p.slice(1);
    }).join(' ');
  }

  const getCustomerView = () => {
    getCustomerByID(customerID).then(result => {
      let arr = []
      for(let key in result.customer) {
        let customerData = {
          label: camelToString(key),
          data: result.customer[key]
        }
        arr.push(customerData)
      }
      setCustomer(arr)
      console.log(result)
    }).catch(err => console.log(err))
  }
  return(
    <>
    <Card>
    <Typography variant={'h4'}>Customer Details</Typography>
    {
      customer && customer.map((entry) => {
        return(
          <>
            <Column cx={2}>
            <br/>
            <Typography variant={'subtitle2'}>{entry.label}</Typography>
            <Typography variant={'subtitle1'}>{entry.data}</Typography>
            <br/>
            </Column>
          </>
        )
      })
    }
    </Card>
    </>
  )
}

export default CustomerView;
