import React, { useState, useEffect } from 'react';
import './AssignTeacher.css';
import { Typography, Button, Select, MenuItem, InputLabel, CircularProgress } from '@material-ui/core';
import Card from '../../../../components/core/card/Card';
import { getAllTeachers, assignTeacherToLessons, assignTeacherToSingleLesson } from "../../../../api"

export const AssignTeacher = ({invoiceID, handleAssign, handleClose, isTrial, isLesson}) => {
  const [teacherID, setTeacherID] = useState(null)
  const [teacherEmail, setTeacherEmail] = useState(null)
  const [teachers, setTeachers] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errMessage, setErrMessage] = useState(false)

  useEffect(() => {
    getTeachers()
  }, [])

   const getTeachers = () => {
     getAllTeachers().then(result => {
       setTeachers(result.teachers)
     })
   }

   const handleSelectChange = (e) => {
     setTeacherID(e.target.value)
     setTeacherEmail(e.nativeEvent.target.dataset.email)
   }

   const confirmSelectedTeacher = () => {
     setIsLoading(true)
     if(!isLesson) {
       assignTeacherToLessons(invoiceID, {teacherID: teacherID, teacherEmail: teacherEmail}, !!isTrial)
       .then(result => {
         if(result.success){
           setIsLoading(false)
           handleClose()
           console.log(result);
         } else {
           setIsLoading(false)
          setErrMessage(result.message)
         }
       }).catch(err => setErrMessage('Something went wrong...'))
     } else {
       let lessonID = invoiceID
       assignTeacherToSingleLesson(lessonID, {teacherID: teacherID, teacherEmail: teacherEmail})
       .then(result => {
         if(result.success){
           handleClose()
           setIsLoading(false)
           console.log(result);
         } else {
           setIsLoading(false)
          setErrMessage(result.message)
         }
       }).catch(err => setErrMessage('Something went wrong...'))
     }
   }

  return (
    <>
      <Card padding={'0px 24px 0px 24px'}>
        {
          teachers &&
          <>
          <InputLabel>Assign Teacher</InputLabel>
          <Select
            onChange={(e) => handleSelectChange(e)}
            variant={'outlined'}
            className='teacher-select'
            size={'small'}
          >
            {
              teachers.map(option => {
                return (
                  <MenuItem value={option._id} data-email={option.email} name={option.email}>{option.name}</MenuItem>
                )
              })
            }
          </Select>
          </>
        }
        { !isLoading &&
          <Button onClick={confirmSelectedTeacher} className='confirm-teacher' variant={'outlined'}>OK</Button>
        }
        { isLoading &&
          <div style={{width: '100%', display: 'flex', marginTop: '14px', justifyContent: 'center'}}>
            <CircularProgress/>
          </div>
        }
        {
          errMessage && <Typography variant={'subtitle2'} color='red'>Something went wrong!</Typography>
        }
      </Card>
    </>
  )
}

export default AssignTeacher
