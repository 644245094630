import React, {useState, useEffect} from 'react';
import './Form.css';
import {Link} from 'react-router-dom';
import {Box, Typography, Button, TextField, InputLabel, CircularProgress, Select, MenuItem} from '@material-ui/core';
import {ToggleButtonGroup, ToggleButton} from '@material-ui/lab';
import {DateTimePicker} from '@material-ui/pickers';
import Column from '../column/Column';
import CheckboxTable from '../../checkbox-table/CheckboxTable';

export const Form = ({submitTxt, formGroups, handleSubmit, setFormValues, isLoading, formErr, links, isComplete, openModal}) => {

  const [values, setValues] = useState(Array(formGroups.length).fill(undefined));

  const handleToggleButtonChange = (e,value, i, multiple) => {
    if(!e.target.id && !e.target.value) {
      e.target = e.target.parentNode
    }
    if(values[i] !== e.target.value) {
      if(multiple) {
        e.target.value = value
      }
      let vals = [...values];
      vals[i] = value
      setValues((v) => vals)
      setFormValues(e)
    }
  }

  const handleDateChange = (e, i) => {
    let vals = [...values];
    vals[i] = e
    setValues((v) => vals)
    console.log(formGroups[i])
    let formValue = {
      target: {
        id: formGroups[i]['id'],
        value: e
      }
    }
    setFormValues(formValue)
  }

  const handleSelectChange = (e, i) => {
    let vals = [...values];
    vals[i] = e.target.value
    setValues((v) => vals)
    let formValue = {
      target: {
        id: formGroups[i]['id'],
        value: e.target.value
      }
    }
    setFormValues(formValue)
  }

  const handleCheckboxTableChange = (value, i) => {
    const formValue = {
      target: {
        id: formGroups[i]['id'],
        value: value
      }
    }
    setFormValues(formValue)
    let vals = [...values];
    vals[i] = value
    setValues((v) => vals)
  }

  const handleNumberIncrease = (value, id, i) => {
    let vals = [...values];
    vals[i] = value + 1;
    setValues((v) => vals)
    let formValue = {
      target: {
        id: id,
        value: value + 1
      }
    }
    setFormValues(formValue);
  }

  const handleNumberDecrease = (value, id, i) => {
    let vals = [...values];
    vals[i] = value - 1;
    setValues((v) => vals)
    let formValue = {
      target: {
        id: id,
        value: value - 1
      }
    }
    setFormValues(formValue);
  }



  return (
    <form className={`form-container`} onSubmit={(e) => handleSubmit(e)}>
      {formGroups.map((group, i) => {
        return(
          <>
          { !group.hidden &&
          <Column cx={group.column}>
          <Box m={1} className={`form-group`}>
          <InputLabel className='form-label'>{group.label}</InputLabel>
          { group.type !== 'button-group' && group.type !== 'date-time' && group.type !== 'select' && group.type !== 'dynamic-text' && group.type !== 'checkbox-table' && group.type !== 'action-button' &&
              <>
                <TextField variant="outlined" multiline={group.multiple} minRows={group.multiple ? 2 : 1} size="small" id={group.id} type={group.type} value={group.value}
                  onChange={(e) => setFormValues(e)}
                />
                { group.type == 'number' && group.controls &&
                  <div className={'number-form-controls'}>
                    <p onClick={() => handleNumberIncrease(group.value, group.id, i)} className={'up-arrow'}>▼</p>
                    <p onClick={() => handleNumberDecrease(group.value, group.id, i)} className={'down-arrow'}>▼</p>
                  </div>
                }
              </>
          }
          { group.type == 'dynamic-text' &&
            <div style={{padding: ' 0px 15px', marginTop: '12px'}}>
              <Typography className={'cet-time'} variant={'subtitle1'}>
                {group.value}
              </Typography>
            </div>
          }
          {
            group.type == 'select' &&
            <Select
              id={group.id}
              value={values[i]}
              onChange={(e) => handleSelectChange(e, i)}
              variant={'outlined'}
              className='form-select'
            >
              {
                group.options.map(option => {
                  return (
                    <MenuItem id={group.id} value={option.value}>{option.label}</MenuItem>
                  )
                })
              }
            </Select>
          }
          {
            group.type == 'date-time' &&
            <>
            <Box m={1} className={`form-group`}>
            <Column cx={2}>
            <DateTimePicker
              autoOk
              inputVariant={'outlined'}
              disablePast={true}
              minutesStep={5}
              format={' HH:mm BBBB, EEEE do MMMM yyyy'}
              ampm={false}
              value={values[i]}
              onChange={(v) => handleDateChange(v,i)}
            />
            </Column>
            <Column cx={2}>
            { group.cetTime &&
              <>
              <InputLabel className='form-label converted-time-label'>Teacher Starting Date & Time (PL):</InputLabel>
              <div style={{padding: ' 0px 15px', marginTop: '-2px'}}>
              <Typography className={'cet-time'} variant={'subtitle1'}>{group.cetTime}</Typography>
              </div>
              </>
            }
            </Column>
            </Box>
            </>
          }
          {
            group.type == 'button-group' &&
            <ToggleButtonGroup
              id={group.id}
              color={"primary"}
              value={values[i]}
              default={group.default}
              exclusive={group.multiple ? false : true}
              onChange={(e,v) => handleToggleButtonChange(e,v,i, group.multiple)}>
              {group.options.map((option) => {
                  return(
                    <ToggleButton id={group.id} value={option.value}>{option.label}</ToggleButton>
                  )
              })}
            </ToggleButtonGroup>
          }
          {
            group.type == 'checkbox-table' &&
            <CheckboxTable options={group.options} handleCheckedValues={(e) => handleCheckboxTableChange(e, i)}/>
          }
          {
            group.type == 'action-button' &&
              <Box m={4}>
                <div style={{marginBottom: '2rem'}}>
                  <Button onClick={openModal} variant="outlined">{group.buttonText}</Button>     
                </div>
              </Box>
          }
          </Box>
          </Column>
          }
          </>
        )
      })}

        <Column cx={1}>
        { submitTxt &&
          <Box m={2.369} display="flex" justifyContent="center">
          { !isLoading && !isComplete && <Button variant="outlined" align="center" className='btn-theme form-btn' type='submit'>{submitTxt}</Button> }
          { isLoading && <div style={{marginTop: '14px',width: '100%', display: 'flex', justifyContent: 'center'}}><CircularProgress className="centered" /></div> }
          </Box>
        }
        { links && links.map((link, i) => {
          return (
            <Link className='form-link turquoise-font' to={link.url}>{link.txt}</Link>
          )
        })
        }
        {<InputLabel className='error-label form-link'>{formErr}</InputLabel>}
        </Column>
      </form>
  )
}

export default Form
