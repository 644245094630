import React, {useState, useEffect, useContext} from 'react';
import './LessonDashboard.css';
import {Link, useHistory} from 'react-router-dom';
import Page from '../../../components/core/page/Page';
import Wrapper from '../../../components/core/wrapper/Wrapper';
import Table from '../../../components/core/table/Table';
import Card from '../../../components/core/card/Card';
import Column from '../../../components/core/column/Column';
import Modal from '../../../components/core/modal/Modal';
import { AssignTeacher } from '../payments/assign-teacher/AssignTeacher'
import { RescheduleLesson } from './reschedule-lesson/RescheduleLesson'
import modalContext from "../../../context/ModalContext";
import {Button, Box, Typography} from '@material-ui/core';
import checkAuth from '../../auth/auth-helpers.js';
import { camelToString } from '../../../helpers';
import { format } from 'date-fns';

import {  getLessonsByInvoiceID } from "../../../api"

export const LessonDashboard = ({invoiceID, altView, altActive}) => {
  const { modalOpen, setModalOpen } = useContext(modalContext);
  const [ lessonTableHeader, setLessonTableHeader] = useState(null)
  const [ lessonTableData, setLessonTableData] = useState(null)
  const [ altViewActive, setAltViewActive ] = useState(null)
  const [lessonID, setLessonID] = useState(null)

  const history = useHistory();
  useEffect(() => {
      checkAuth(history)
      getLessons(invoiceID ? invoiceID : null)
  }, [])

  useEffect(() => {
      getLessons(invoiceID)
  }, [invoiceID])

  const getLessons = (invID) => {
    getLessonsByInvoiceID(invID ? invID : null).then(result => {
      if(result) {
        let headerData = []
        for(let key in result.lessons[0]) {
          if(key == 'date' || key == 'calendarURL' || key == 'teacherID') {
            if(key == 'calendarURL') {
              headerData.push({data: 'Calendar', value: key})
            } else if(key == 'teacherID') {
              headerData.push({data: 'Teacher', value: 'teacher'})
            } else {
              headerData.push({data: camelToString(key), value: key})
            }
          }
        }
        headerData.push({data: 'Action', value: 'edit'})
        setLessonTableHeader(headerData)
        let tableData = []
        let sortedResult = result.lessons.sort((a, b) => b.date < a.date ? 1: -1)
        sortedResult.forEach((lesson, i) => {
          let rowData = []
          for (let key in lesson) {
            if(key == 'calendarURL') {
              rowData.push({data: lesson[key], value: key, button: true, buttonLabel: 'Event'})
            } if (key == 'date') {
              rowData.push({data: `${format(new Date(lesson[key]), 'HH:mm EE dd/MM/yy')} ${format(new Date(lesson[key]), 'zzz')}`, value: key})
            }
            if ( key == 'teacherID') {
              if (lesson['subTeacherID']) {
                console.log('subTeacherID')
                console.log(lesson['subTeacherID'])
                rowData.push({data: `${result.teachers[`${lesson['subTeacherID']}`]} (Sub)`, value: 'teacher'})
              } else {
                rowData.push({data: result.teachers[`${lesson[key]}`], value: key})
              }
            }
            // } else if (key == 'teacherID' && lesson['subTeacherID']) {
            //   rowData.push({data: result.teachers[`${lesson['subTeacherID']}`], value: 'teacher'})
            // }
          }
          rowData.push({data: lesson['_id'], value: 'edit', button: true, buttonLabel: 'Edit'})
          tableData.push({rows: rowData})
        });
        setLessonTableData(tableData)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const handleActionRowClick = (target, row, cell) => {
    const selectedColumnID = lessonTableData[row].rows[cell].value
    const selectedCellValue = lessonTableData[row].rows[cell].data
    if(selectedColumnID == 'calendarURL') {
      window.open(selectedCellValue)
    }
    if(selectedColumnID == 'edit') {
      setAltViewActive(true);
      setLessonID(selectedCellValue)
    }
  }

  const handleSuccess = () => {
    setAltViewActive(false)
    getLessons(invoiceID)
  }

  return (
    <>
      <Card>
        <div style={{minHeight: '580px'}} className={`${altViewActive ? 'no-overflow' : ''} modal-scroll-container`}>
        <Table onActionRowClick={handleActionRowClick} headers={lessonTableHeader} data={lessonTableData} />
        {altView &&
        <div className={`${altViewActive ? 'visible' : ''} alt-view-container`}>
          <div className={'alt-view-inner-container'}>
            <span onClick={() => setAltViewActive(false)} className='close-btn'>x</span>
            <AssignTeacher isLesson={true} invoiceID={lessonID} handleClose={handleSuccess}/>
            <RescheduleLesson lessonID={lessonID} handleSuccess={handleSuccess}/>
          </div>
        </div>}
        </div>
      </Card>
    </>
  )
}
export default LessonDashboard;
