import React, {useState} from 'react';
import './gpt3-generator.css';
import {Link} from 'react-router-dom'
import Page from '../../../../components/core/page/Page';
import Wrapper from '../../../../components/core/wrapper/Wrapper';
import Card from '../../../../components/core/card/Card';
import{ Button, Typography, TextField, Select, MenuItem} from '@material-ui/core';
import { generateGPT3Sentence } from '../../../../api';

export const GPT3Generator = () => {
  const [returnedSentences, setReturnedSentences] = useState([])
  const [prompt, setPrompt] = useState(null)
  const [age, setAge] = useState(null)
  const generateSentence = () => {
    generateGPT3Sentence({prompt:`Generate a sentence for a ${age} year old using the word "${prompt}"`})
    .then(res => {
      console.log(res.response.map(sentence => sentence.text))
      setReturnedSentences(res.response.map(sentence => sentence.text))
    });
  }

  const handlePrompt = (e) => {
    setPrompt(e.target.value)
  }

  const handleAge = (e) => {
    console.log(e)
    setAge(e.target.value)
  }

  return (
    <Page>
      <Wrapper>
      <Card background={'#fff'}>
        <div className="prompt-container">
        <Typography variant={'h5'}>Write a sentence for </Typography>
        <Select variant={'outlined'} onChange={(e) => handleAge(e)} className="prompt-select">
          <MenuItem value={5}>P1</MenuItem>
          <MenuItem value={6}>P2</MenuItem>
          <MenuItem value={7}>P3</MenuItem>
          <MenuItem value={8}>P4</MenuItem>
          <MenuItem value={9}>P5</MenuItem>
          <MenuItem value={10}>P6</MenuItem>
        </Select>
        <Typography variant={'h5'}> using the word </Typography>
        <TextField onChange={(e) => handlePrompt(e)} className='prompt-field'></TextField>
        </div>
        {returnedSentences.length !== 0 &&
        <div className={'results-container'}>
          {
            returnedSentences.map(sentence => {
            return (
              <div className={'result-container'}>
              <Button style={{marginRight: '14px'}} variant={'outlined'}>Save</Button><Typography variant={'h6'}>{sentence}</Typography>
              </div>
            )
          })
        }
          </div>
        }
        <div className="prompt-container" style={{marginTop: '14px'}}>
        <Button variant={'outlined'} onClick={generateSentence}>Generate Sentence</Button>
        </div>
        </Card>
      </Wrapper>
    </Page>
  )
}

export default GPT3Generator;
