import React, {useState, useEffect, useContext} from 'react';
import './GenerateInvoice.css';
import {Typography, Box} from '@material-ui/core';
import {useHistory} from 'react-router-dom'
import Page from '../../../../components/core/page/Page';
import Wrapper from '../../../../components/core/wrapper/Wrapper';
import Form from '../../../../components/core/form/Form';
import Card from '../../../../components/core/card/Card';
import Column from '../../../../components/core/column/Column';
import RescheduleInvoiceLesson from '../reschedule-invoice-lesson/RescheduleInvoiceLesson';
import { generateInvoice, getAllCustomers, getAllTeachers } from '../../../../api';
import { addMinutes, addHours, format, addDays, intervalToDuration, getMonth } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz';
import { INVOICE_FORM_TEMPLATE } from './InvoiceFormTemplate';
import checkAuth from '../../../auth/auth-helpers.js';
import Modal from '../../../../components/core/modal/Modal.js';
import modalContext from '../../../../context/ModalContext.js';

export const GenerateInvoice = () => {
  const history = useHistory();
  const initialiseForm = (template) => {
    let obj = {}
    template.forEach(group => {
      if(!group.hidden){
        obj[group.id] = null
      }
    })
    return obj;
  }

  const [invoiceFormValues, setInvoiceFormValues] = useState(INVOICE_FORM_TEMPLATE)
  const [invoiceForm, setInvoiceForm] = useState(initialiseForm(INVOICE_FORM_TEMPLATE))
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [invoiceErr, setInvoiceErr] = useState('')

  const [customInvoiceToggle, setCustomInvoiceToggle] = useState(false)
  const [newCustomerToggle, setNewCustomerToggle] = useState(false)
  const [kindergartenToggle, setKindegartenToggle] = useState(false)
  const [lessonFrequencyToggle, setLessonFrequencyToggle] = useState(1)
  const [customers, setCustomers] = useState()
  const [teachers, setTeachers] = useState(null)
  const [numberOfMakeupLessons, setNumberOfMakeupLessons] = useState(0)
  const [calculatedInvoiceAmount, setCalculatedInvoiceAmount] = useState('')
  const [paymentLink, setPaymentLink] = useState('')
  const [lessonList, setLessonList] = useState([])
  const [rescheduledLessons, setRescheduledLessons] = useState([])
  const [makeupLessons, setMakeupLessons] = useState([])
  const [cancelledLessons, setCancelledLessons] = useState([])

  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false)
  const { modalOpen, setModalOpen } = useContext(modalContext);


  useEffect(() => {
    checkAuth(history)
    getCustomers()
    getTeachers()
  }, [])

  const getCustomers = () => {
    getAllCustomers().then(data => {
      let arr = []
      data.customers.forEach((customer, i) => {
        let customerOptionData = {
          value: customer._id,
          label: customer.customerName,
          age: customer.studentYear
        }
        arr.push(customerOptionData);
      });
      setCustomers(arr)
      const index = invoiceFormValues.findIndex(item =>
        item.id === 'invoiceRecipientSelect'
      )
      let formVals = invoiceFormValues;
      formVals[index].options = arr
      setInvoiceFormValues(formVals)
    }).catch(err => console.log(err))
  }

  const getTeachers = () => {
    getAllTeachers().then(data => {
      console.log(data)
      let teacherArr = []
      data.teachers.forEach(teacher => {
        teacherArr.push({
          value: teacher._id,
          label: teacher.name,
          // email: teacher.email,
          // zoom: teacher.zoomURL
        })
      })
      console.log(teacherArr)
      setTeachers(teacherArr)
      const index = invoiceFormValues.findIndex(item =>
        item.id === 'teacherSelect'
      )
      let formVals = invoiceFormValues;
      formVals[index].options = teacherArr
      setInvoiceFormValues(formVals)
    }).catch(err => console.log(err))
  }

  const calculateInvoiceAmount = (amt, qty) => {
    if (amt) { return amt * qty}
    else {
       return 198.00 * qty
    }
  }

  // This section handle the template, form values are handled in setInvoice

  const handleTimezones = (date, index) => {
    let form = []
    invoiceFormValues.forEach((group, i) => {
      let grp = group;
      if(group.id.includes(`startingDateTime_${index}`)) {
        grp.cetTime = format(addMinutes(date, 6 * -60 ), 'HH:mm BBBB, EEEE do MMMM yyyy')
      }
      form.push(grp)
    })
    setInvoiceFormValues(form)
  }

  const handleCustomInvoiceToggle = (hasCustomInvoice) => {
    setCustomInvoiceToggle(!customInvoiceToggle);
    let form = invoiceFormValues;
    let invForm = invoiceForm;
    form.forEach(group => {
      if(group.toggleID == 0) {
        group.hidden = !group.hidden;
        if(group.hidden) {
        delete invForm[group.id];
        } else {
          invForm[group.id] = null;
        }
      }
    });
    setInvoiceFormValues(form)
  }

  const handleNewCustomerToggle = (isNew) => {
    setNewCustomerToggle(!newCustomerToggle);
    let form = invoiceFormValues;
    let invForm = invoiceForm;
    form.forEach((group, i) => {
      if(group.toggleID == 1) {
        group.hidden = !group.hidden;
        if(group.hidden) {
        delete invForm[group.id];
        } else {
          invForm[group.id] = null;
        }
      }
    });
    setInvoiceFormValues(form)
  }

  const handleKindergartenToggle = (toggle) => {
    setKindegartenToggle(toggle)
    let form = invoiceFormValues;
    let invForm = invoiceForm;
    form.forEach((group, i) => {
      if(group.id == 'lessonType') {
        if(toggle[0] == "P") {
          if(parseInt(toggle[1]) <= 3) {
            group.options = [
              {
                value: 'Reading',
                label: 'Reading'
              },
              {
                value: 'Speaking',
                label: 'Speaking'
              },
              {
                value: 'Writing',
                label: 'Writing'
              }
            ]
          } else {
            group.options = [
              {
                value: 'Reading',
                label: 'Reading'
              },
              {
                value: 'Speaking',
                label: 'Speaking'
              },
              {
                value: 'Writing',
                label: 'Writing'
              }
            ]
          }
        } else if (toggle[0] == "K") {
          group.options = [
            {
              value: 'Phonics',
              label: 'Phonics'
            },
            {
              value: 'Storytelling',
              label: 'Storytelling'
            },
          ]
        }
      }
    });
    setInvoiceFormValues(form)
  }

  const handleSubjectTypeChange = (subjectType) => {
    let formTemplate = invoiceFormValues
    formTemplate.forEach((group, i) => {
      if(group.id == 'lessonType') {
        let firstSubject = subjectType.split(',')[0]
        group.label = `Lesson Type (First Lesson: ${firstSubject})`
      }
    })
    setInvoiceFormValues(formTemplate)
  }

  const handleMakeupLessonAmount = (amt) => {
    setNumberOfMakeupLessons(amt);
    let invForm = invoiceForm;
    let form = []
    invoiceFormValues.forEach((group, i) => {
      if(group.id.includes('makeupLessonDateTime')) {
        if(parseInt(group.id[group.id.length - 1]) <= amt) {
          group.hidden = false
        } else {
          group.hidden = true
        }
        if(group.hidden) {
          delete invForm[group.id];
        } else if (!invForm[group.id]) {
          invForm[group.id] = null;
        }
      }
      form.push(group)
    })
    setInvoiceFormValues(form)
  }

  const handleLessonFrequency = (freq) => {
    setLessonFrequencyToggle(freq);
    let invForm = invoiceForm;
    let form = []
    invoiceFormValues.forEach((group, i) => {
      if(group.id.includes('startingDateTime')) {
        if(parseInt(group.id[group.id.length - 1]) <= freq) {
          group.hidden = false
        } else {
          group.hidden = true
        }
        if(group.hidden) {
          delete invForm[group.id];
        } else if (!invForm[group.id]) {
          invForm[group.id] = null;
        }
      }
      form.push(group)
    })
    setInvoiceFormValues(form)
  }

  const handleLastDayOfInvoice = (dateArray, qty) => {
    dateArray.sort((a,b)=>a.getTime()-b.getTime());
    if(addHours(dateArray[0], 24 * 7) <= dateArray[dateArray.length - 1]) {
      alert('WARNING! Dates are a week or more apart!');
    }

    const countLessonsUntilEndOfMonth = (date, month, depth) => {
      let nxtWeek = addHours(date, 24 * 7)
      if (nxtWeek.getMonth() == month) {
        return countLessonsUntilEndOfMonth(nxtWeek, month, depth + 1)
      } else {
        return {date: date, depth: depth};
      }
    }

    let newDateArr = [];
    for (let date of dateArray) {
      newDateArr.push(countLessonsUntilEndOfMonth(date, date.getMonth(), 1));
    }
    let sortedDateArr = newDateArr.map(a => a.date).sort((a,b)=>a.getTime()-b.getTime());
    let depthTotal = newDateArr.map(dArr => dArr.depth).reduce((sum, a) => sum + a, 0);;
    let lastLessonOfMonthInInvoice = sortedDateArr[sortedDateArr.length - 1]
    let form = []
    invoiceFormValues.forEach((group, i) => {
      if(group.id == 'lessonQuantity') {
        group.value = depthTotal
      }
      if(group.id == 'lastLessonInInvoice') {
        group.value = format(lastLessonOfMonthInInvoice, 'EEE. do MMMM yyyy')
      }
      form.push(group)
    })
    console.log(sortedDateArr)
    setInvoiceFormValues(form)
    setLessonList(handleLessonList(dateArray, lastLessonOfMonthInInvoice));
  }

  const handleLessonList = (dates, lastLesson) => {
    let newDateArr = []
    const addNewDate = (d) => {
      const nextDate = new Date(addDays(d, 7))
      if(nextDate <= lastLesson) {
        newDateArr.push(nextDate)
        addNewDate(nextDate)
      }
    }
    dates.forEach((date, i) => {
      newDateArr.push(date);
      addNewDate(date)
    })
    return newDateArr.sort((a,b)=>a.getTime()-b.getTime());
  }

  const handleLessonQuantity = (qty) => {
    let invForm = invoiceForm;
    let form = []
    let dateArr = []
    for(let key in invoiceForm) {
      if(key.includes('startingDateTime')) {
        dateArr.push(invoiceForm[key])
      }
    }
    dateArr.sort((a,b)=>a.getTime()-b.getTime());
    let lastDate = dateArr[dateArr.length - 1];
    let weekCount = 0
    for(let i = dateArr.length; i < qty; i++) {
      if( i % dateArr.length == 0) {
        weekCount++;
      }
      lastDate = addHours(dateArr[i % dateArr.length], 24 * 7 * weekCount)
    }

    invoiceFormValues.forEach((group, i) => {
      if(group.id == 'lessonQuantity') {
        group.value = qty
      }
      if(group.id == 'lastLessonInInvoice') {
        group.value = format(lastDate, 'EEE. do MMMM yyyy')
      }
      form.push(group)
    })
    setInvoiceFormValues(form)
  }

  const handleRescheduleModalOpen = () => {
    let makeupArr = [];
    if(invoiceForm.makeupLessonDateTime_1) {
      makeupArr.push(invoiceForm.makeupLessonDateTime_1)
    }
    if(invoiceForm.makeupLessonDateTime_2) {
      makeupArr.push(invoiceForm.makeupLessonDateTime_2)
    }
    if(invoiceForm.makeupLessonDateTime_3) {
      makeupArr.push(invoiceForm.makeupLessonDateTime_3)
    }
    if(invoiceForm.makeupLessonDateTime_4) {
      makeupArr.push(invoiceForm.makeupLessonDateTime_4)
    }
    console.log(invoiceForm)
    console.log(makeupArr)
    setMakeupLessons(makeupArr)
    setModalOpen(true)
  }

  useEffect(() => {
    let qtyInv = invoiceFormValues.filter(inv => inv.id == 'lessonQuantity').pop()
    qtyInv = qtyInv.value
    let form = invoiceForm;
    form['invoiceAmount'] = calculateInvoiceAmount(
      form.hasCustomInvoice ? form.customInvoiceAmount : null,
      qtyInv - cancelledLessons.length
    );
    setCalculatedInvoiceAmount(form['invoiceAmount'])
    setInvoiceForm(form);
  }, [cancelledLessons])

  const setInvoice = (e) => {
    let form = invoiceForm;
    console.log(form)
    for (let key in form) {
      if(key === e.target.id) {
        form[key] = e.target.value
      }
    }

    if(form.ageGroup) {
      if(kindergartenToggle !== form.ageGroup[0]) {
        handleKindergartenToggle(form.ageGroup)
      }
    }

    if(form.lessonType) {
      handleSubjectTypeChange(form.lessonType)
    }

    if(form.startingDateTime_1) {
      handleTimezones(form.startingDateTime_1, 1);
      if(form.startingDateTime_2 === undefined) {
        handleLastDayOfInvoice([form.startingDateTime_1],
        form.lessonQuantity)
      }
    }
    if(form.startingDateTime_2) {
      handleTimezones(form.startingDateTime_2, 2);
      if(form.startingDateTime_3 === undefined) {
        handleLastDayOfInvoice(
          [form.startingDateTime_1, form.startingDateTime_2],
          form.lessonQuantity
        )
      }
    }
    if(form.startingDateTime_3) {
      handleTimezones(form.startingDateTime_3, 3);
      if(form.startingDateTime_4 === undefined) {
        handleLastDayOfInvoice(
          [form.startingDateTime_1, form.startingDateTime_2, form.startingDateTime_3],
          form.lessonQuantity
        )
      }
    }
    if(form.startingDateTime_4) {
      handleTimezones(form.startingDateTime_4, 4);
      handleLastDayOfInvoice(
        [form.startingDateTime_1, form.startingDateTime_2, form.startingDateTime_3, form.startingDateTime_4],
        form.lessonQuantity
      )
    }
    if(form.lessonQuantity) {
        handleLessonQuantity(form.lessonQuantity);
    }
    if(form.lessonFrequency) {
      if(lessonFrequencyToggle !== form.lessonFrequency) {
        handleLessonFrequency(form.lessonFrequency)
      }
    }
    if(form.numberOfMakeupLessons) {
      if(numberOfMakeupLessons !== form.numberOfMakeupLessons) {
        handleMakeupLessonAmount(form.numberOfMakeupLessons)
      }
    }
    if(form.hasCustomInvoice) {
      let formBool = form.hasCustomInvoice == 'yes' ? true : false
        if (formBool !== customInvoiceToggle) {
          handleCustomInvoiceToggle(form.hasCustomInvoice)
        }
    }
    if(form.isNewCustomer) {
      let formBool = form.isNewCustomer == 'no' ? true : false
        if (formBool !== newCustomerToggle) {
          handleNewCustomerToggle(form.isNewCustomer)
        }
    }
    let qtyInv = invoiceFormValues.filter(inv => inv.id == 'lessonQuantity').pop()
    qtyInv = qtyInv.value
    form['invoiceAmount'] = calculateInvoiceAmount(
      form.hasCustomInvoice ? form.customInvoiceAmount : null,
      qtyInv - cancelledLessons.length
    );
    setCalculatedInvoiceAmount(form['invoiceAmount'])
    setInvoiceForm(form);
  }

  const submitInvoice = (e) => {
    console.log(invoiceForm)
    console.log(invoiceFormValues);
    e.preventDefault();
    let invForm = invoiceForm;
    let lastLessonInv = null;
    let qtyInv = null;
    for (let key in invoiceForm) {
      if(key == 'lastLessonInInvoice') {
        lastLessonInv = invoiceFormValues.filter(inv => inv.id == 'lastLessonInInvoice').pop()
        lastLessonInv = lastLessonInv.value
        invForm[key] = lastLessonInv.value
      }
      if(key == 'lessonQuantity') {
        qtyInv = invoiceFormValues.filter(inv => inv.id == 'lessonQuantity').pop()
        qtyInv = qtyInv.value
        invForm[key] = qtyInv.value
      }
      if(!invForm[key] && key !== 'lastLessonInInvoice' && key !== 'lessonQuantity' && key !== 'rescheduleLesson') {
        setInvoiceErr(key + ' is required!')
        return;
      }
    }
    setIsLoading(true);

    const generateHongKongDateString = (d) => {
      let timezoneOffset = d.getTimezoneOffset();
      let arr = JSON.stringify(addMinutes(d, timezoneOffset - 2 * timezoneOffset)).split('.')
      return `${arr[0]}+08:00`.substring(1)
    }

    let isNew = invoiceForm.isNewCustomer == 'yes'
    let isCustom = invoiceForm.hasCustomInvoice == 'yes'
    let startingDatesArr = []
    for(let key in invoiceForm) {
      if(key.includes('startingDateTime')) {
        startingDatesArr.push(generateHongKongDateString(invoiceForm[key]))
      }
    }

    let makeupDatesArr = []
    for(let key in invoiceForm) {
      if(key.includes('makeupLessonDateTime')) {
        makeupDatesArr.push(generateHongKongDateString(invoiceForm[key]))
      }
    }


    let invoiceData = {
      isNewCustomer: isNew,
      payee: isNew ? invoiceForm.invoiceRecipient : invoiceForm.invoiceRecipientSelect,
      student: isNew ? invoiceForm.invoiceStudent : null,
      contactNumber: isNew ? invoiceForm.contactNumber : null,
      contactEmail: isNew ? invoiceForm.contactEmail : null,
      ageGroup: isNew ? invoiceForm.ageGroup : null,
      frequency: invoiceForm.lessonFrequency,
      lessonType: invoiceForm.lessonType.split(','),
      lessonQty: qtyInv,
      customPrice: isCustom ? invoiceForm.customInvoiceAmount : null,
      startingDates: startingDatesArr,
      makeupDates: makeupDatesArr,
      rescheduledDates: rescheduledLessons.map(d => ({old: generateHongKongDateString(new Date(d.old)), new: generateHongKongDateString(new Date(d.new))})),
      lessonDates: lessonList.filter(l => !cancelledLessons.map(c => JSON.stringify(c)).includes(JSON.stringify(l))).map(d => generateHongKongDateString(new Date(d))),
      lastLessonInInvoice: lastLessonInv,
      teacherID: invoiceForm.teacherSelect
      // firstLessonModule: `${invoiceForm.firstModuleOfInvoice}-${invoiceForm.firstLessonOfInvoice}`,
    }
    console.log(invoiceData)
    generateInvoice(invoiceData)
    .then(res => {
      if(res.success) {
        setIsLoading(false)
        setIsComplete(true)
        setInvoiceErr(false)
        setPaymentLink(res.invoice.paymentURL);
      } else {
        setIsLoading(false);
        setInvoiceErr('Something went wrong when adding the invoice')
      }
    })
    .catch(err => {
      console.log(err);
    })
  }

  const generateWhatsAppMessage = () => {
    let message = `Hi, your new invoice is ready! \n 
    Payment Link: ${paymentLink} \n \n Here is your schedule for this coming month, \n \n`; 
      
      [...lessonList, ...makeupLessons].sort((a,b)=>a.getTime()-b.getTime()).forEach(l => {
        if(cancelledLessons.map(c => JSON.stringify(c)).includes(JSON.stringify(l))) {
          message += `~${format(l, 'dd/MM EEE - HH:mm')}~ (Cancelled) \n`
        } else if (makeupLessons.map(m => JSON.stringify(m)).includes(JSON.stringify(l))) {
          console.log(makeupLessons)
          message += `_${format(l, 'dd/MM EEE - HH:mm')}_ (Make up Class) \n`
        } else if (rescheduledLessons.map(r => JSON.stringify(r.old)).includes(JSON.stringify(l))) {
          console.log(rescheduledLessons);
          console.log(l)
          console.log(rescheduledLessons.map(r => JSON.stringify(r.old)).indexOf(JSON.stringify(l)))
          message += `~${format(l, 'dd/MM EEE - HH:mm')}~ -> *${format(rescheduledLessons[rescheduledLessons.map(r => JSON.stringify(r.old)).indexOf(JSON.stringify(l))].new, 'dd/MM EEE - HH:mm')}* \n`
        } else {
          message += `${format(l, 'dd/MM EEE - HH:mm')} \n`
        }
      })

    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/+4407832593029?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  }

  return (
    <Page>
      <Wrapper>
        <div className='generate-invoice-container'>
          <Card>
            <div className='generate-invoice-header'>
              <div style={{display: 'flex', justifyContent: 'center'}}>
              <Column cx={1}>
              <Typography variant='h5' style={{paddingLeft: '12px'}}>
              Generate a payment invoice
              </Typography>
              </Column>
              </div>
            </div>
            <div className={'w-full'}>
            {teachers && customers && <Form
              submitTxt={`Generate${calculatedInvoiceAmount ? ` $HK${calculatedInvoiceAmount}` : ''} Invoice`}
              formGroups={invoiceFormValues}
              handleSubmit={submitInvoice}
              setFormValues={setInvoice}
              isLoading={isLoading}
              formErr={invoiceErr}
              isComplete={isComplete}
              openModal={handleRescheduleModalOpen}
            />}
            </div>
            { paymentLink &&
              <div>
                <div style={{width: '100%',display: 'flex', justifyContent: 'center', background: '#a0f5a6', borderRadius: '7px', padding: '14px', margin: '14px', transform: 'translateY(14px)'}}>
                  <Typography variant='h6'>{paymentLink}</Typography>
                </div>
                <div className={'w-full block flex justify-center mt-8 text-xl font-bold font-fredoka'}>
                  <button onClick={generateWhatsAppMessage} className={'bg-emerald-400 rounded px-4 py-1'}>Send via WhatsApp</button>
                </div>
              </div>
            }
          </Card>
        </div>
      </Wrapper>
      <Modal open={rescheduleModalOpen} component={<RescheduleInvoiceLesson lessons={lessonList} makeupLessons={makeupLessons} handleRescheduledLessons={setRescheduledLessons} handleCancelledLessons={setCancelledLessons}/>}></Modal>
    </Page>
  )
}

export default GenerateInvoice;
