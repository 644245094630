import React from 'react';
import './Card.css';

export const Card = ({classes, children, background, padding, margin, minHeight}) => {
  return (
    <div className={`card-container`} style={{padding: margin}}> 
    {/* Mwahahahahaha*/} 
    {/*Not funny, You are stupid, me from the past */}
      <div className={`card-inner-container ${classes}`} style={{background: background, padding: padding, minHeight: minHeight}}>
      {children}
      </div>
    </div>
  )
}

export default Card;
