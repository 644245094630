import React, {useState, useEffect} from 'react';
import './CreateTrial.css';
import Page from '../../../../components/core/page/Page';
import Wrapper from '../../../../components/core/wrapper/Wrapper';
import Form from '../../../../components/core/form/Form';
import Card from '../../../../components/core/card/Card';
import Column from '../../../../components/core/column/Column';
import { createTrial, getAllTeachers } from '../../../../api';
import { addMinutes, format, parseISO } from 'date-fns';
import { TRIAL_FORM_TEMPLATE } from './TrialFormTemplate';
import {useHistory} from 'react-router-dom'
import checkAuth from '../../../auth/auth-helpers.js';
import {camelToString} from '../../../../helpers'

export const CreateTrial = () => {

  const history = useHistory();
  const initialiseForm = (template) => {
    let obj = {}
    template.forEach(group => {
      if(!group.hidden){
        obj[group.id] = null
      }
    })
    return obj;
  }
  const [isLoading, setIsLoading] = useState(false);
  const [trialErr, setTrialErr] = useState('')
  const [trialLessonForm, setTrialLessonForm] = useState(initialiseForm(TRIAL_FORM_TEMPLATE))
  const [trialFormTemplate, setTrialFormTemplate] = useState(TRIAL_FORM_TEMPLATE)
  const [teachers, setTeachers] = useState(null);
  const [teacherEmails, setTeacherEmails] = useState([])
  const [teacherZooms, setTeacherZooms] = useState([])

  useEffect(() => {
    checkAuth(history)
    getTeachers();
  }, [])

  const getTeachers = () => {
    getAllTeachers().then(data => {
      let teacherArr = [{value: null, label: 'Not Sure'}]
      let emailArr = []
      let zoomArr = []
      data.teachers.forEach((teacher, i) => {
        teacherArr.push({
          value: teacher._id,
          label: teacher.name
        });
        emailArr.push(teacher.email);
        zoomArr.push(teacher.zoomURL);
      });
      setTeachers(teacherArr)
      setTeacherEmails(emailArr)
      setTeacherZooms(zoomArr)
      console.log(emailArr)
      const index = trialFormTemplate.findIndex(item =>
        item.id === 'trialTeacher'
      )
      let trialTemplate = trialFormTemplate;
      trialTemplate[index].options = teacherArr
      setTrialFormTemplate(trialTemplate)
    }).catch(err => console.log(err))
  }

  const handleTimezone = (date) => {
    let form = trialFormTemplate
    form[form.findIndex(tT => tT.id === 'trialDate')].cetTime = format(addMinutes(date, 6 * -60 ), 'hh:mm BBBB, EEEE do MMMM yyyy')
    setTrialFormTemplate(form)
  }

  const setTrial = (e) => {
    let form = trialLessonForm;
    for (let key in form) {
      if(key === e.target.id) {
        form[key] = e.target.value;
      }
    }
    if(form.trialDate) {
      handleTimezone(form.trialDate)
    }
    setTrialLessonForm(form)
  }

  const submitTrial = (e) => {
    e.preventDefault();
    for(let key in trialLessonForm) {
      if(!trialLessonForm[key] && key !== 'trialTeacher' && key !== 'trialDescription') {
        setTrialErr(camelToString(key) + ' is required!')
        return;
      }
    }
    setIsLoading(true)
    const teacherIdx = teachers.findIndex(item =>
      item.value === trialLessonForm.trialTeacher
    )

    const generateHongKongDateString = (d) => {
      let timezoneOffset = d.getTimezoneOffset();
      let arr = JSON.stringify(addMinutes(d, timezoneOffset - 2 * timezoneOffset)).split('.')
      return `${arr[0]}+08:00`.substring(1)
    }


    let trialData = {
      parentName: trialLessonForm.trialName,
      studentName: trialLessonForm.trialStudent,
      email: trialLessonForm.trialEmail,
      phone: trialLessonForm.trialContactNo,
      description: trialLessonForm.trialDescription ? trialLessonForm.trialDescription : null,
      age: trialLessonForm.trialAge,
      teacher: trialLessonForm.trialTeacher ? trialLessonForm.trialTeacher : null,
      teacherEmail: trialLessonForm.trialTeacher ? teacherEmails[teacherIdx - 1]: null,
      teacherZoom: trialLessonForm.trialTeacher ? teacherZooms[teacherIdx - 1]: null,
      date: generateHongKongDateString(trialLessonForm.trialDate)
    }
    // console.log(trialData.date);
    createTrial(trialData)
    .then(res => {
      console.log(res);
      if(res.success) {
        history.push(`/invoices`);
      } else {
        alert(`There was a problem when submitting the form : ${res.message}`)
      }
      setIsLoading(false)
    }).catch(err => {
      setIsLoading(false);
      alert('failed')
      console.log(err)
    })
  }

  return(
    <Page>
      <Wrapper>
        <Card>
          <Form
            submitTxt={'Create Trial'}
            formGroups={trialFormTemplate}
            handleSubmit={submitTrial}
            setFormValues={setTrial}
            isLoading={isLoading}
            formErr={trialErr}
          />
        </Card>
      </Wrapper>
    </Page>
  )
}

export default CreateTrial;
